import { combineReducers } from 'redux';
import MembersReducer from "./reducer/members";
import SaintLeaderReducer from "./reducer/saintleaders";
import SabhaReducer from "./reducer/sabha";
import NewMemberReducer from "./reducer/newMembers";
import CommonFields from './reducer/commonFields';
import MemberFlagsReducer from './reducer/memberFlags';
import UserReducer from './reducer/user';

const reducerBind = combineReducers({
  CommonFields,
  MembersReducer,
  SaintLeaderReducer,
  SabhaReducer,
  NewMemberReducer,
  MemberFlagsReducer,
  UserReducer
});
const rootReducer = (state, action) => {
  if (action.type === 'authentication/resetReducer') {
    // check for action type
    state = undefined;
  }
  if (action.type === 'reset/resetState') {
    // check for action type
    state = undefined;
  }

  return reducerBind(state, action);
};

export default rootReducer;
