import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import MenuBox from "../../Common/Menu";
import MuplitplePlaylistMenu from "./MultiplePlaylistMenu";
import SinglePlaylistMenu from "./SinglePlaylistMenu";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  createPlaylistSection,
  deletePlaylistSection,
  getAllSection,
  getLiveNowVideos,
  getPlayList,
  updatePlaylistPositionInSection,
  updatePlaylistSection,
} from "./actionCreator";
import { toastr } from "../../../services/common.service";

const menuList = ["Live Now", "Single Playlist", "Multiple Playlist"];
const DraggableSection = ({ id, index, moveSection, children }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "section",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
    },
    drop(item) {
      moveSection(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type: "section",
    item: () => {
      return { id, index };
    },
  });
  drag(drop(ref));
  return <div ref={ref}>{children}</div>;
};
const CustomizeLayout = () => {
  const [selectedOption, setSelectOption] = useState(null);
  const [isMultiSelectModalOpen, setIsMultiSelectModalOpen] = useState(false);
  const [isSingleSelectModalOpen, setIsSingleSelectModalOpen] = useState(false);
  const [selectedSections, setSelectedSections] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});
  const [dragInx, setDragInx] = useState();
  const [dropInx, setDropInx] = useState();
  const [livenow, setLivenow] = useState([]);

  const getAllPlayList = async () => {
    const playListData = await getPlayList();
    setPlaylists(playListData.data || []);
  };

  const getSection = async () => {
    const sectionData = await getAllSection();
    const defaultOrderedSections = sectionData?.data?.map((section) => ({
      ...section,
      order: section.position,
    }));
    setSelectedSections(defaultOrderedSections);
  };

  const handleEditSection = (sectionId) => {
    const currentDetail = selectedSections.find(
      (item) => item._id === sectionId
    );
    setSelectedSection(currentDetail);
    if (currentDetail?.type === "single") {
      setIsSingleSelectModalOpen(true);
    }
    if (currentDetail.type === "multiple") {
      setIsMultiSelectModalOpen(true);
    }
  };

  const handleDeleteSection = async (sectionId) => {
    const result = await deletePlaylistSection({ id: sectionId });
    if (result.data) {
      toastr.show("Section Deleted successfully", "success");
      setSelectedSections((prevSection) =>
        prevSection.filter((section) => section._id !== sectionId)
      );
    }
  };

  useEffect(() => {
    getAllPlayList();
    getSection();
  }, []);

  const handleCloseSingleSelectModal = () => {
    setSelectOption(null);
    setIsSingleSelectModalOpen(false);
    setSelectedSection({});
  };

  const handleCloseMultiSelectModal = () => {
    setSelectOption(null);
    setIsMultiSelectModalOpen(false);
    setSelectedSection({});
  };

  const liveNowSection = async () => {
    try {
      const liveVideos = await getLiveNowVideos();

      let videoIds = [];
      if (liveVideos.data?.length > 0) {
        let liveData = liveVideos.data;
        setLivenow(liveData);
        await liveData.map((item) => videoIds.push(item));

        let newData = {
          name: "live now",
          type: "livenow",
          livenow: videoIds,
          position: selectedSections.length,
        };
        const response = await createPlaylistSection(newData);
        let dataWithId = { ...newData, _id: response.data };
        setSelectedSections((prevSections) => [...prevSections, dataWithId]);
      }

      setSelectOption([]);
    } catch (error) {}
  };

  useEffect(() => {
    switch (selectedOption) {
      case 1:
        liveNowSection();
        break;
      case 2:
        setIsSingleSelectModalOpen(true);
        break;

      case 3:
        setIsMultiSelectModalOpen(true);
        break;

      default:
        break;
    }
  }, [selectedOption]);

  const moveSection = async (dragIndex, hoverIndex) => {
    setDragInx(dragIndex);
    setDropInx(hoverIndex);
    const draggedSection = selectedSections[dragIndex];
    const newSections = [...selectedSections];
    newSections.splice(dragIndex, 1);
    newSections.splice(hoverIndex, 0, draggedSection);
    setSelectedSections(newSections);
  };

  const publishPlaylistSection = async () => {
    let newList = [...selectedSections];
    let updatedItem = [];
    newList.map((item, index) =>
      updatedItem.push({ _id: item._id, position: index })
    );
    const result = await updatePlaylistPositionInSection({
      sections: updatedItem,
    });
    if (result.data) {
      toastr.show(result.data, "success");
    }
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
          <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
            <div>
              <div className="text-[25px] text-[#212121]">
                Customize Playlist
              </div>
              <p className="text-sm">Customize the layout for app</p>
            </div>
            <MenuBox
              menuButton={
                <div className="flex items-center h-10 w-[150px] rounded-[10px] cursor-pointer text-white font-medium bg-primary px-4">
                  <Icon icon="ic:baseline-plus" className="mr-2 text-2xl" /> Add
                  Section
                </div>
              }
              menuItems={menuList}
              setSelectOption={setSelectOption}
              selectedOption={selectedOption}
              isDisabled={selectedSections.some(
                (item) => item.livenow.length > 0
              )}
            />
          </div>
          <div className="h-auto flex flex-col justify-between gap-5 rounded-[10px] bg-white">
            <div className="p-4">
              <div className="flex-grow overflow-auto">
                <div className="flex flex-col gap-3">
                  {selectedSections.length > 0 &&
                    selectedSections.map((item, index) => {
                      let playlistType = item?.type || "";
                      return (
                        <DraggableSection
                          key={item._id}
                          id={item._id}
                          index={index}
                          moveSection={moveSection}
                        >
                          <div className="flex items-center gap-5 p-5 border border-[#D7D7D7] rounded-sm">
                            <div className=" flex justify-center">
                              <Icon
                                icon="fluent:re-order-16-filled"
                                className="text-xl"
                              />
                            </div>
                            <div className="w-[calc(100%_-_40px)]">
                              <div className="flex justify-between">
                                {playlistType === "single" && (
                                  <div className="mb-3 flex-grow">
                                    Single playlist:{" "}
                                    <span className="font-semibold ml-1">
                                      {item?.name} &#40;
                                      {item["playlist"]?.length}
                                      &#41;
                                    </span>
                                  </div>
                                )}
                                {playlistType === "multiple" && (
                                  <div className="mb-3 flex-grow">
                                    Multiple playlist:{" "}
                                    <span className="font-semibold ml-1">
                                      {item?.name} &#40;
                                      {item["playlist"]?.length}
                                      &#41;
                                    </span>
                                  </div>
                                )}
                                {playlistType === "livenow" && (
                                  <div className="mb-3 flex-grow">Live Now</div>
                                )}
                                <div className="flex items-center">
                                  {playlistType === "livenow" ? (
                                    <></>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handleEditSection(item._id)
                                      }
                                    >
                                      <Icon
                                        icon="bxs:edit"
                                        className="mr-3 text-gray300 hover:text-primary text-2xl"
                                      />
                                    </button>
                                  )}
                                  <button
                                    onClick={() =>
                                      handleDeleteSection(item._id)
                                    }
                                  >
                                    <Icon
                                      icon="material-symbols:delete"
                                      className="text-gray300 hover:text-red400 text-2xl"
                                    />
                                  </button>
                                </div>
                              </div>
                              <div className="flex items-center gap-3 overflow-auto">
                                {item["playlist"]?.length > 0 &&
                                  item["playlist"].map((playlist, index) => {
                                    return (
                                      <div className="relative" key={index}>
                                        <img
                                          src={
                                            playlist.thumbnailImages
                                              ? playlist.thumbnailImages.default
                                              : "/assets/image/no-thumbnail.png"
                                          }
                                          alt="thumbnail"
                                          className="min-w-[80px] w-[80px] h-[50px]"
                                        />
                                        {playlistType === "single" && (
                                          <span className="absolute bottom-1 right-1 text-xs p-0.5 bg-opacity-80 text-white bg-black">
                                            32:50
                                          </span>
                                        )}
                                        {playlistType === "multiple" && (
                                          <div className="absolute inset-y-0 right-0 w-[70%] bg-opacity-80 bg-black">
                                            <div className="h-full flex flex-col justify-center">
                                              <div className="text-white text-sm text-center">
                                                {playlist?.videos.length}
                                              </div>
                                              <Icon
                                                icon="solar:playlist-bold"
                                                className="text-white text-xl mx-auto"
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                                {item["livenow"]?.length > 0 &&
                                  item["livenow"].map((playlist, index) => {
                                    return (
                                      <div className="relative" key={index}>
                                        <img
                                          src={
                                            playlist.thumbnailImages
                                              ? playlist.thumbnailImages.default
                                              : "/assets/image/no-thumbnail.png"
                                          }
                                          alt="empty-thumbnail"
                                          className="min-w-[80px] w-[80px] h-[50px]"
                                        />
                                        {playlistType === "multiple" && (
                                          <div className="absolute inset-y-0 right-0 w-[70%] bg-opacity-80 bg-black">
                                            <div className="h-full flex flex-col justify-center">
                                              <div className="text-white text-sm text-center">
                                                {playlist?.videos.length}
                                              </div>
                                              <Icon
                                                icon="solar:playlist-bold"
                                                className="text-white text-xl mx-auto"
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                        </DraggableSection>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="flex justify-end p-4">
              <button
                type="button"
                className="mr-3 inline-flex justify-center uppercase rounded-md font-semibold bg-white px-4 py-2 text-sm  text-red-600 hover:text-red-600 focus:outline-none"
              >
                Cancel
              </button>
              <button
                type="button"
                className="inline-flex justify-center uppercase rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary focus:outline-none"
                onClick={() => publishPlaylistSection()}
              >
                Publish
              </button>
            </div>
          </div>
        </div>

        <MuplitplePlaylistMenu
          isOpen={isMultiSelectModalOpen}
          closeModal={handleCloseMultiSelectModal}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
          playlists={playlists}
          sectionData={selectedSection}
        />
        <SinglePlaylistMenu
          isOpen={isSingleSelectModalOpen}
          closeModal={handleCloseSingleSelectModal}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
          sectionData={selectedSection}
          playlists={playlists}
        />
      </DndProvider>
    </>
  );
};

export default CustomizeLayout;
