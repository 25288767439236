import _ from "lodash";
import { api } from "../../services/api.service";

export const getAllPendingRequests = async (data) => {
  const response = await api.post("admin/request/pending-list", data);
  return response;
}

export const sendMessageReply = async (_id, message, status) => {
  const response = await api.post("admin/request/reply", { _id, message, reply: true, status });
  return response;
}

export const editRequest = async (data) => {
  const response = await api.post("admin/request/editRequest", data );
  return response;
}

export const editMessageReply = async (replyId, requestId, message) => {
  const response = await api.post("admin/request/reply/edit", { replyId, requestId, message, });
  return response;
}

export const deleteMessageReply = async (replyId, requestId) => {
  const response = await api.post("admin/request/reply/delete", { replyId, requestId });
  return response;
}

export const setRequestPriority = async (_id, priority) => {
  const response = await api.post("admin/request/setRequestPriority", { requestId: _id, priority });
  return response;
}

export const reorderBooks = async (data) => {
  const response = await api.post("books/reorder", data);
  return response;
}

export const getSubject = async () => {
  const response = await api.get("admin/request/subject");
  return response;
};
  
export const createSubject = async (data) => {
  const response = await api.post("admin/request/subject", data);
  return response;
};

export const updateSubject = async (data) => {
  const response = await api.put(`admin/request/subject`, data);
  return response;
};

export const getStatus = async () => {
  const response = await api.get("admin/request/status");
  return response;
};
  
export const createStatus = async (data) => {
  const response = await api.post("admin/request/status", data);
  return response;
};

export const updateStatus = async (data) => {
  const response = await api.put(`admin/request/status`, data);
  return response;
};