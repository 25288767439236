import React, { useEffect, useRef, useState } from "react";

import CreatableSelect from "react-select/creatable";

export default function CreatableMultiselectMain(props) {
  const {
    placeholder,
    label,
    value,
    id,
    required,
    options,
    isMulti,
    isDisabled,
    onChange,
    handleCreate,
    minWidth,
    maxWidth,
    menuPosition = "absolute",
    className,
  } = props;

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      border: "1px solid #b0bec5",
      borderRadius: "8px",
      minHeight: "30px",
      minWidth: minWidth ? minWidth : "240px",
      maxWidth: maxWidth,
      backgroundColor: "#fff",
      "&:focus": {
        outline: "none",
        borderColor: "#b0bec5",
      },
      "&:hover": {
        border: "1px solid #b0bec5",
      },
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      color: "#C6C6C6",
      fontSize: "14px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black", // Text color for selected and non-selected options
      backgroundColor: state.isSelected
        ? "blue"
        : state.isFocused
        ? "lightgray"
        : state.isDisabled
        ? "gray"
        : "white", // Background color for selected, focused, and non-focused options
    }),
  };

  const modalRef = useRef(null);

  return (
    <div className={`${className ? className : "w-full"}`} ref={modalRef}>
      {label && (
        <label
          htmlFor={id}
          className="text-sm text-gray300 font-semibold mb-2 inline-block"
        >
          {label}
          {required ? <span className="text-red400">*</span> : <></>}
        </label>
      )}
      <CreatableSelect
        isMulti={isMulti}
        isClearable
        isDisabled={isDisabled}
        placeholder={placeholder}
        onChange={onChange}
        onCreateOption={handleCreate}
        options={options}
        value={value || ""}
        styles={customStyles}
        menuPosition={menuPosition}
      />
    </div>
  );
}
