import { useRoutes } from "react-router-dom";
import { SevaList } from "../../../Components";
import SevaListIndex from "../../../Components/seva/SevaListIndex";

export default function SevaRouter() {
  const sevaRouter = [
    {
      path: "/list/*",
      element: (
        <>
          <SevaList />
        </>
      ),
    },
    {
      path: "/list/:id/*",
      element: (
        <>
          <SevaListIndex />
        </>
      ),
    },
  ];

  const SevaRouter = useRoutes(sevaRouter);

  return SevaRouter;
}
