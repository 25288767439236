import React, { useEffect, useMemo, useState } from "react";
import DatePickerField from "../Common/Rsuite-Datepicker";
import { Teams } from "../../constants/common.constants";
import SelectField from "../Common/Input/SelectField";
import moment from "moment";
import {
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { Icon } from "@iconify/react";
import { getAllSocieties } from "./actionCreator";
import CreatableMultiselectMain from "../Common/Input/CreatableMultiselectMain";

const data = [
    { date: '2024-03-20', team: 'Team A', societyName: 'Swaminarayan Society', societyIndex: '101', id: 1 },
    { date: '2024-03-21', team: 'Team B', societyName: 'Ghanshyam Society', societyIndex: '102', id: 2 },
    { date: '2024-03-22', team: 'Team C', societyName: 'Harikrushna Society', societyIndex: '103', id: 3 },
    { date: '2024-03-23', team: 'Team D', societyName: 'Narayan Society', societyIndex: '104', id: 4 },
    { date: '2024-03-24', team: 'Team E', societyName: 'Krishna Society', societyIndex: '105', id: 5 },
]

const ManagePadharamani = () => {

    const [formValues, setFormValues] = useState({
        date: null,
        team: null,
        index: ''
    });
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnPinning, setColumnPinning] = useState({
        right: ["actions"]
    });
    const [societyList, setSocietyList] = useState([])
    const [selectedIndexes, setSelectedIndexes] = useState([]);

    const setValues = (key, value) => {
        if (key === 'index') {
            // Only allow numbers
            if (!/^\d*$/.test(value)) {
                return;
            }
        }
        setFormValues(prev => ({
            ...prev,
            [key]: value
        }));
    };


    const handleSave = () => {
        console.log("🚀 ~ ManagePadharamani ~ formValues:", formValues)
    }

    const fetchAllSociety = async () => {
        try {
            const response = await getAllSocieties()
            setSocietyList(response.data.societyIndex)

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchAllSociety()
    }, [])

    const getMatchingSocieties = () => {
        if (!selectedIndexes.length || !societyList.length) return [];

        return societyList.filter(society =>
            selectedIndexes.some(selected =>
                Number(selected.value) === society.societyIndex
            )
        );
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "date",
                header: () => <span>Date</span>,
                cell: (info) => moment(info.getValue()).format('DD/MM/YYYY'),
            },
            {
                accessorKey: "team",
                header: () => <span>Team</span>,
                cell: (info) => info.getValue(),
            },
            {
                accessorKey: "societyName",
                header: () => <span>Society Name</span>,
                cell: (info) => info.getValue(),
            },
            {
                accessorKey: "societyIndex",
                header: () => <span>Society Index</span>,
                cell: (info) => info.getValue(),
            },
            {
                id: "actions",
                size: 50,
                header: () => <span className="text-center">Actions</span>,
                cell: (info) => {
                    return (
                        <div className="flex gap-2 justify-center">
                            <button className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900" onClick={() => handleEdit(info.row.original)}>
                                <Icon
                                    icon="ic:baseline-edit"
                                    className="text-2xl transition-all duration-150 text-black900"
                                />
                            </button>
                            <button className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900" onClick={() => handleDelete(info.row.original.id)}>
                                <Icon icon="material-symbols:delete" className="text-2xl transition-all duration-150 text-black900" />
                            </button>
                        </div>
                    );
                },
            },
        ],
        []
    );

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
            pagination,
            columnPinning,
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        onColumnPinningChange: setColumnPinning,
    });

    const handleEdit = (row) => {
        console.log('Edit:', row);
    };

    const handleDelete = (id) => {
        console.log('Delete:', id);
    };

    return (
        <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
            <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
                <div className="text-[25px] text-[#212121]">On the occasion of the 15th Patotsav of Ladila Shri Ghanshyam Maharaj</div>
            </div>
            <form className="border border-primary bg-white rounded-lg p-6 mt-5 shadow-[rgba(0,0,0,0.1)_0px_10px_50px]">
                <div className="flex items-center justify-between">
                    <div className="flex gap-5">
                        <div className="">
                            <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                Select Date
                                <span className="text-red400">*</span>
                            </label>
                            <DatePickerField
                                placeholder="Select Date"
                                id="02"
                                value={formValues.date ? new Date(formValues.date) : null}
                                onChange={(date) => setValues('date', moment(date).toISOString())}
                            />
                        </div>
                        <div className="w-60">
                            <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                Select Team
                                <span className="text-red400">*</span>
                            </label>
                            <SelectField
                                className="!h-9 placeholder:text-black900 "
                                required={false}
                                placeholder="Select Gender"
                                isMulti={false}
                                defaultOptions={Teams}
                                value={formValues.team}
                                onChange={(val) => setValues("team", val)}
                            />
                        </div>
                        <div>
                            <label className="text-sm text-gray300 font-semibold mb-2 inline-block">
                                Socity Index
                                <span className="text-red400">*</span>
                            </label>
                            <CreatableMultiselectMain
                                isClearable={true}
                                isMulti={true}
                                placeholder="Select Multiple Indexes"
                                options={[]}
                                value={selectedIndexes || null}
                                onChange={(e) => setSelectedIndexes(e)}
                                minWidth="200px"
                            />
                        </div>
                    </div>
                    <div className="mt-8 text-right">

                        <button
                            type="button"
                            className="inline-flex self-end ml-2 -mt-1.5  items-center h-9 w-5 bg-primary min-w-[90px] justify-center text-white text-sm px-2 py-2 rounded-lg"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
            {
                getMatchingSocieties().length > 0 &&
                <div className="border border-primary bg-white rounded-lg p-3 mt-5 shadow-[rgba(0,0,0,0.1)_0px_10px_50px]">
                    <div className="flex items-center gap-2">
                        {getMatchingSocieties().map((society, index) => (

                            <div key={index} className="p-2 text-base bg-white border border-gray400 shadow rounded">
                                {society.society}
                            </div>
                        ))}
                    </div>
                </div>
            }
            <div className="border border-primary bg-white rounded-lg p-6 mt-5 shadow-[rgba(0,0,0,0.1)_0px_10px_50px]">
                <div className="overflow-x-auto relative">
                    <table className="w-full member-table">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className="border-b border-stone-200 text-sm text-left bg-[#F2F2F2] text-[#535353]">
                                    {headerGroup.headers.map((header) => (
                                        <th
                                            key={header.id}
                                            className={`p-2 last:text-center ${header.column.getIsPinned() ? 'sticky z-10 bg-[#F2F2F2]' : ''
                                                } ${header.column.getIsPinned() === 'right' ? 'right-0' : ''
                                                }`}
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id} className="border-b border-stone-200 text-sm bg-white">
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            key={cell.id}
                                            className={`p-2 last:text-center ${cell.column.getIsPinned() ? 'sticky z-10 bg-white' : ''
                                                } ${cell.column.getIsPinned() === 'right' ? 'right-0' : ''
                                                }`}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination controls */}
                <div className="flex items-center justify-end gap-2 mt-3">
                    <button
                        className="border rounded w-8 h-8 grid place-items-center"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        <Icon icon="lucide:chevron-left" className="text-xl" />
                    </button>
                    <button
                        className="border rounded w-8 h-8 grid place-items-center"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        <Icon icon="lucide:chevron-right" className="text-xl" />
                    </button>
                    <span className="flex items-center gap-1">
                        <div>Page</div>
                        <strong>
                            {table.getState().pagination.pageIndex + 1} of{" "}
                            {table.getPageCount()}
                        </strong>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ManagePadharamani