import React from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { Icon } from "@iconify/react/dist/iconify.js";
import SelectField from "../Input/SelectField";
import { languageType } from "../../../constants/common.constants";

const DrawerComponent = ({
  open,
  onClose,
  onTextChange,
  textValue,
  selectLanguage,
  setLanguageType,
  setTitle,
  title,
  saveKatha,
}) => {
  return (
    <Drawer open={open} onClose={onClose} size={630}>
      <div className="flex items-center px-4">
        <Typography
          variant="h5"
          color="blue-gray"
          className="flex-1 text-center"
        >
          Edit Katha
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={onClose}>
          <Icon icon="material-symbols:close" width="24" height="24" />
        </IconButton>
      </div>

      <div className="p-3 rounded-md">
        <div className="text-sm font-semibold text-black900 mb-1">Font</div>
        <div className="w-3/4">
          <SelectField
            className="!h-9 placeholder:text-black900"
            required={false}
            placeholder="Select Font"
            isMulti={false}
            defaultOptions={languageType}
            value={selectLanguage}
            onChange={(val) => {
              console.log("Selected value:", val);
              setLanguageType(val);
            }}
          />
        </div>
      </div>

      <div className="flex flex-col gap-6 p-4">
        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold text-black900 mb-1">Title</div>
          <div className="w-3/4">
            <div className="flex gap-2">
              <Input
                placeholder="Enter Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
              <button
                className="bg-blue-500 text-white px-2 py-1 rounded-md"
                onClick={() => saveKatha()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <Textarea
          rows={40}
          label="Text"
          onChange={onTextChange}
          value={textValue}
          className="text-center"
          style={{
            fontFamily: selectLanguage.label
              ? selectLanguage.label
              : "sans-serif",
            overflowY: "auto",
            maxHeight: "700px",
            fontSize: "24px",
            lineHeight: "1.5",
          }}
        />
      </div>
    </Drawer>
  );
};

export default DrawerComponent;
