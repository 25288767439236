import React, { Fragment, useState } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import TextField from "../Common/Input/TextField";
import CreatableMultiselectMain from "../Common/Input/CreatableMultiselectMain";
import { useSelector } from "react-redux";

const MultiEditDetailModel = (props) => {
    const { closeMemeberModel, updateDetail, open, showEditField } = props;
    const { societyList, streetNameList, cityList, stateList, countryList, villageList, occupationList, educationList } = useSelector((state) => state.UserReducer);
    const [formData, setFormData] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        occupation: "",
        education: "",
        society: "",
        city: "",
        postalCode: "",
        state: "",
        country: "",
        streetName: "",
        village: ""
    });

    const handleChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" open={open} onClose={() => { }}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/50" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="mt-2">
                                        <div className="grid grid-cols-3 gap-4">
                                            {showEditField === 'name' &&
                                                <TextField
                                                    id="firstName"
                                                    type="text"
                                                    className="placeholder:gray100"
                                                    label="First Name"
                                                    required={true}
                                                    placeholder="Enter first name"
                                                    value={formData.firstName}
                                                    onChange={(e) => handleChange("firstName", e.target.value)}
                                                />}
                                            {showEditField === 'name' && <TextField
                                                id="middleName"
                                                type="text"
                                                className="placeholder:gray100"
                                                label="Middle Name"
                                                placeholder="Enter middle name"
                                                value={formData.middleName}
                                                onChange={(e) => handleChange("middleName", e.target.value)}
                                            />}
                                            {showEditField === 'name' && <TextField
                                                id="lastName"
                                                type="text"
                                                className="placeholder:gray100"
                                                label="Last Name"
                                                required={true}
                                                placeholder="Enter last name"
                                                value={formData.lastName}
                                                onChange={(e) => handleChange("lastName", e.target.value)}
                                            />}
                                        </div>

                                        <div className="grid grid-cols-2 gap-4 mt-4">
                                            {showEditField === 'occupation' && <CreatableMultiselectMain
                                                className="placeholder:gray100"
                                                label="Occupation"
                                                id="16"
                                                isMulti={false}
                                                placeholder="Select Occupation"
                                                value={
                                                    formData?.occupation
                                                        ? [
                                                            {
                                                                label: formData?.occupation,
                                                                value: formData?.occupation,
                                                            },
                                                        ]
                                                        : []
                                                }
                                                options={occupationList}
                                                onChange={(e) => {
                                                    handleChange("occupation", e?.value);
                                                }}
                                            />}
                                            {showEditField === 'education' && <CreatableMultiselectMain
                                                className="placeholder:gray100"
                                                label="Education"
                                                id="16"
                                                isMulti={false}
                                                placeholder="Select Education"
                                                value={
                                                    formData?.education
                                                        ? [
                                                            {
                                                                label: formData?.education,
                                                                value: formData?.education,
                                                            },
                                                        ]
                                                        : []
                                                }
                                                options={educationList}
                                                onChange={(e) => {
                                                    handleChange("education", e?.value);
                                                }}
                                            />}
                                        </div>

                                        <div className="mt-4">
                                            {showEditField === 'address' &&
                                                <CreatableMultiselectMain
                                                    className="placeholder:gray100"
                                                    label="Society"
                                                    id="07"
                                                    isMulti={false}
                                                    placeholder="Select Society"
                                                    value={formData.society ? [{ label: formData?.society, value: formData?.society }] : []}
                                                    options={societyList}
                                                    onChange={(e) => { handleChange('society', e?.value) }}
                                                />}
                                            {showEditField === 'address' && <CreatableMultiselectMain
                                                className="placeholder:gray100"
                                                label="Area/Street"
                                                id="08"
                                                isMulti={false}
                                                placeholder="Select Area/Street"
                                                value={formData.streetName ? [{ label: formData?.streetName, value: formData?.streetName }] : []}
                                                options={streetNameList}
                                                onChange={(e) => { handleChange('streetName', e?.value) }}
                                            />}
                                        </div>

                                        <div className="mt-4">
                                            {showEditField === 'village' && <CreatableMultiselectMain
                                                className="placeholder:gray100"
                                                label="Native Village"
                                                id="22"
                                                isMulti={false}
                                                placeholder="Select Native Village"
                                                value={
                                                    formData?.village
                                                        ? [
                                                            {
                                                                label: formData?.village,
                                                                value: formData?.village,
                                                            },
                                                        ]
                                                        : []
                                                }
                                                options={villageList}
                                                onChange={(e) => {
                                                    handleChange("village", e?.value);
                                                }}
                                            />}
                                        </div>


                                        <div className="grid grid-cols-2 gap-4 mt-4">
                                            {showEditField === 'address' && <CreatableMultiselectMain
                                                className="placeholder:gray100"
                                                label="City"
                                                id="09"
                                                isMulti={false}
                                                placeholder="Select City"
                                                value={formData.city ? [{ label: formData?.city, value: formData?.city }] : []}
                                                options={cityList}
                                                onChange={(e) => { handleChange('city', e?.value) }}
                                            />}
                                            {showEditField === 'address' && <TextField
                                                id="postalCode"
                                                type="text"
                                                className="placeholder:gray100"
                                                label="Pin Code"
                                                placeholder="Enter pin code"
                                                value={formData.postalCode}
                                                onChange={(e) => handleChange("postalCode", e.target.value)}
                                            />}
                                        </div>

                                        <div className="grid grid-cols-2 gap-4 mt-4">
                                            {showEditField === 'address' && <CreatableMultiselectMain
                                                className="placeholder:gray100"
                                                label="State"
                                                id="10"
                                                isMulti={false}
                                                placeholder="Select State"
                                                value={formData.state ? [{ label: formData?.state, value: formData?.state }] : []}
                                                options={stateList}
                                                onChange={(e) => { handleChange('state', e?.value) }}
                                            />}
                                            {showEditField === 'address' && <CreatableMultiselectMain
                                                className="placeholder:gray100"
                                                label="Country"
                                                id="12"
                                                isMulti={false}
                                                placeholder="Select Country"
                                                value={formData.country ? [{ label: formData?.country, value: formData?.country }] : []}
                                                options={countryList}
                                                onChange={(e) => { handleChange('country', e?.value) }}
                                            />}
                                        </div>
                                    </div>

                                    <div className="mt-4 flex justify-end">
                                        <button
                                            type="button"
                                            className="mr-3 inline-flex justify-center rounded-md border bg-white hover:bg-red-50 border-orange-900 px-4 py-2 text-sm font-medium text-orange-900  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeMemeberModel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => updateDetail({
                                                firstName: formData.firstName,
                                                middleName: formData.middleName,
                                                lastName: formData.lastName,
                                                occupation: formData.occupation,
                                                education: formData.education,
                                                address: {
                                                    society: formData.society,
                                                    city: formData.city,
                                                    postalCode: formData.postalCode,
                                                    state: formData.state,
                                                    country: formData.country,
                                                    streetName: formData.streetName
                                                },
                                                village: formData.village
                                            })}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white hover:bg-orange-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                        >
                                            Update Details
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default MultiEditDetailModel;
