import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import CreatableMultiselectMain from "../Common/Input/CreatableMultiselectMain";
import { Button } from "@material-tailwind/react";

import {
  deleteSocietyIndex,
  getIndexedSocieties,
  updateSocietyIndex,
  updateSocietyLocation,
} from "./actionCreator";
import { toastr } from "../../services/common.service";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import ReportPdf from "./ReportPdf";
import SocietyPdf from "./SocietyPdf";
import LocationAddModel from "./LocationAddModel";

const columnHelper = createColumnHelper();

let freeze = false; //mark delay
let timer; //saved timer

const SocietyList = () => {
  const [indexedSocieties, setIndexedSocieties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [disablePaginationButton, setDisablePaginationButton] = useState(false);
  const [sortConfig, setSortConfig] = useState([]);
  const [currentSociety, setCurrentSociety] = useState("");
  const [showLocationAddModel, setShowLocationAddModel] = useState(false);

  const inputRefs = useRef([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [minIndex, setMinIndex] = useState("");
  const [maxIndex, setMaxIndex] = useState("");
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [streetName, setStreetName] = useState([]);
  const [city, setCity] = useState([]);

  const { streetNameList, cityList } = useSelector(
    (state) => state.MembersReducer
  );
  const [lastAPIParams, setLastAPIParams] = useState({});

  const toggleSort = (key) => {
    setSortConfig((prevConfig) => {
      const existingConfig = prevConfig.find((config) => config.key === key);
      if (existingConfig) {
        return prevConfig.map((config) =>
          config.key === key
            ? {
                ...config,
                direction: config.direction === "asc" ? "desc" : "asc",
              }
            : config
        );
      }
      return [{ key, direction: "asc" }];
    });
  };

  const fetchSocieties = async (p) => {
    setDisablePaginationButton(true);
    const sortQuery =
      sortConfig
        .map(
          (config) => `${config.direction === "desc" ? "-" : ""}${config.key}`
        )
        .join(",") || "societyIndex";

    try {
      const params = {};
      if (searchTerm) params.searchTerm = searchTerm;
      if (minIndex || minIndex === 0) params.minIndex = parseFloat(minIndex);
      if (maxIndex || maxIndex === 0) params.maxIndex = parseFloat(maxIndex);
      if (selectedIndexes?.length > 0)
        params.selectedIndexes = selectedIndexes
          ?.map((item) => item.value)
          .join(",");
      if (streetName?.length > 0)
        params.streetName = streetName?.map((item) => item.value).join(",");
      if (city?.length > 0)
        params.city = city?.map((item) => item.value).join(",");

      const response = await getIndexedSocieties({
        page: currentPage || 1,
        pageSize: pageSize || 10,
        sort: sortQuery,
        ...params,
      });

      if (response.data) {
        setIndexedSocieties(response.data.societyIndex || response.data);
        setTotalPages(response.data.totalPages || 1);
        setLastAPIParams(params);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDisablePaginationButton(false);
    }
  };

  const fetchSocietiesDebounced = async () => {
    freeze = true; //set mark for stop calls
    return new Promise(async (res, err) => {
      //return promise
      let p = new Promise((res, err) => {
        if (freeze) clearTimeout(timer); //remove  prev timer
        timer = setTimeout(async () => {
          freeze = false;
          await fetchSocieties();
          res(true);
        }, 500);
      });

      p.then(function (x) {
        res(x);
      });
    });
  };

  useEffect(() => {
    fetchSocietiesDebounced();
  }, [
    searchTerm,
    minIndex,
    maxIndex,
    streetName,
    city,
    selectedIndexes,
    pageSize,
    currentPage,
    sortConfig,
  ]);

  const updateSociety = async (society) => {
    try {
      const updateObj = {
        societyIndex: society.societyIndex,
      };

      if (society.society) {
        updateObj.society = society.society;
      }

      if (society.streetName) {
        updateObj.streetName = society.streetName;
      }
      if (society.city) {
        updateObj.city = society.city;
      }

      const response = await updateSocietyIndex(updateObj);
      if (response.data) {
        toastr.show("Society Index Updated Successfully", "success");
      }
    } catch (error) {
      console.log(error);
      toastr.show(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  const handleClearRangeFilter = () => {
    setSearchTerm("");
    setMinIndex("");
    setMaxIndex("");
    setStreetName([]);
    setCity([]);
    setSelectedIndexes([]);
  };

  const columns = [
    columnHelper.accessor("society", {
      cell: (info) => info.getValue(),
      header: () => <span>Society</span>,
      size: 800,
    }),
    columnHelper.accessor("streetName", {
      cell: (info) => info.getValue(),
      header: () => <span>StreetName</span>,
      size: 800,
    }),
    columnHelper.accessor("city", {
      cell: (info) => info.getValue(),
      header: () => <span>City</span>,
      size: 800,
    }),
    columnHelper.display({
      header: () => <span>Index</span>,
      id: "societyIndex",
      cell: (props) => {
        const society = props.row.original; // Get the row data
        const index = props.row.index; // Get the row index

        return (
          <div className="flex items-center justify-center">
            <input
              placeholder="Add Index"
              type="number"
              className="w-[100px] rounded-[7px] border border-blue-gray-200 bg-transparent px-2 py-1 focus:outline-0 mr-2"
              value={society.societyIndex || ""}
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(value) => {
                const _listIndex = _.cloneDeep(indexedSocieties);
                _listIndex[index].societyIndex = value?.target?.value;
                setIndexedSocieties(_listIndex);

                setTimeout(() => inputRefs.current[index]?.focus(), 0);
              }}
            />
            <button
              className="bg-[#007AFF] text-white px-2 py-1 rounded-[5px]"
              onClick={() => {
                updateSociety(society);
              }}
            >
              Update
            </button>
          </div>
        );
      },
      size: 200,
    }),
    // implement delete action
    columnHelper.display({
      header: () => <span>Action</span>,
      id: "delete",
      cell: (props) => (
        <div className="flex items-center justify-center">
          <Icon
            icon="material-symbols:add"
            className="text-green-500 text-2xl cursor-pointer mr-2"
            onClick={() => openLocationAddModel(props.row.original)}
          />
          <Icon
            icon="material-symbols:delete"
            className="text-red-500 text-2xl cursor-pointer"
            onClick={() => handleDelete(props.row.original._id)}
          />
          <Icon
            icon="material-symbols:location-on"
            className="text-blue-500 text-2xl cursor-pointer mr-2"
            onClick={() =>
              window.open(`${props.row.original.location}`, "_blank")
            }
          />
        </div>
      ),
      size: 200,
    }),
  ];

  const openLocationAddModel = (data) => {
    setCurrentSociety(data);
    setShowLocationAddModel(true);
  };

  const handleLocation = async (location) => {
    try {
      const response = await updateSocietyLocation({
        location: location,
        id: currentSociety._id,
      });
      if (response.data) {
        toastr.show("Society Index Deleted Successfully", "success");
        indexedSocieties.map((item) => {
          if (item._id + "" === currentSociety._id + "") {
            item.location = location;
          }
        });
        setIndexedSocieties(indexedSocieties);
      }
    } catch (error) {
      toastr.show(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
    setShowLocationAddModel(false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteSocietyIndex(id);
      if (response.data) {
        toastr.show("Society Index Deleted Successfully", "success");
        fetchSocieties();
      }
    } catch (error) {
      toastr.show(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  const table = useReactTable({
    data: indexedSocieties,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handlePageChange = (direction) => {
    switch (direction) {
      case "prev":
        if (currentPage > 0) {
          setCurrentPage((prev) => prev - 1);
        }
        break;

      case "next":
        if (currentPage < totalPages) {
          setCurrentPage((prev) => prev + 1);
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="h-[calc(100vh_-_58px)]  2xl:p-[20px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[10px] mb-[10px] border-b-[#D7D7D7] border-b">
          <div className="text-[24px] text-[#212121]">Indexed Society List</div>
          <div className="flex gap-5 px-10">
            <Button
              color="blue"
              className="flex items-center min-w-[100px]"
              onClick={() => {}}
            >
              {/* <Icon icon={"ic:baseline-plus"} size={1} className="mr-1" /> */}{" "}
              Soc. Zone
            </Button>
          </div>
        </div>

        <div className="bg-white h-[calc(100%_-_130px)] mb-3 w-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="flex items-center mb-3">
            <div className="w-56 relative">
              <input
                type="text"
                placeholder="Search societies..."
                className="bg-gray200 text-sm h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Icon
                icon="iconamoon:search-light"
                className="absolute right-2 top-[50%] translate-y-[-50%] text-lg text-[#969696]"
              />
            </div>
            <div className="flex items-center">
              <div className="flex items-center gap-2 ml-4">
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    placeholder="Min Index"
                    className="w-24 bg-gray200 text-sm h-9 text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm"
                    value={minIndex}
                    onChange={(e) => setMinIndex(e.target.value)}
                  />
                  <span className="text-gray-500">to</span>
                  <input
                    type="number"
                    placeholder="Max Index"
                    className="w-24 bg-gray200 text-sm h-9 text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm"
                    value={maxIndex}
                    onChange={(e) => setMaxIndex(e.target.value)}
                  />
                </div>
              </div>

              <div className="ml-4">
                <CreatableMultiselectMain
                  isClearable={true}
                  isMulti={true}
                  placeholder="Select Multiple Indexes"
                  options={[]}
                  value={selectedIndexes || null}
                  onChange={(e) => setSelectedIndexes(e)}
                  minWidth="200px"
                />
              </div>

              <div className="ml-4">
                <CreatableMultiselectMain
                  isClearable={true}
                  isMulti={true}
                  placeholder="Enter Street Name"
                  options={streetNameList}
                  value={streetName || null}
                  onChange={(e) => setStreetName(e)}
                  minWidth="200px"
                />
              </div>
              <div className="ml-4">
                <CreatableMultiselectMain
                  isClearable={true}
                  isMulti={true}
                  placeholder="Enter City"
                  options={cityList}
                  value={city || null}
                  onChange={(e) => setCity(e)}
                  minWidth="150px"
                />
              </div>

              <button
                className="text-blue-500 hover:text-blue-700 text-sm ml-4"
                onClick={handleClearRangeFilter}
              >
                Clear Filter
              </button>

              <SocietyPdf lastAPIParams={lastAPIParams} />
              <ReportPdf lastAPIParams={lastAPIParams} />
            </div>
          </div>
          <div className="h-[calc(100%_-_66px)] overflow-auto">
            <table className="w-full">
              <thead className="sticky top-0">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="text-sm bg-[#F2F2F2] text-[#535353]"
                  >
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="text-left font-semibold p-2"
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            className={clsx(
                              header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              "last:text-center flex items-center justify-center"
                            )}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {header.column.columnDef.id === "societyIndex" && (
                              <div
                                onClick={() => {
                                  toggleSort("societyIndex");
                                }}
                              >
                                <Icon
                                  icon="mdi:sort"
                                  className="ml-2 text-lg cursor-pointer"
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr
                    key={row.id}
                    className="border-b border-stone-200 text-sm hover:bg-gray-50"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-4 py-2 text-center first:text-left"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2 mt-0 sticky p-2 px-4 bg-white border-t-2 border rounded-[10px]">
          <button
            className="border-blue-100 border-b-2 border rounded-lg w-7 h-7 grid place-items-center"
            onClick={() => handlePageChange("prev")}
            disabled={currentPage === 1 || disablePaginationButton}
          >
            <Icon icon="lucide:chevron-left" className="text-xl" />
          </button>
          <button
            className="border-blue-100 border-b-2 border rounded-lg w-7 h-7 grid place-items-center"
            onClick={() => handlePageChange("next")}
            disabled={currentPage === totalPages || disablePaginationButton}
          >
            <Icon icon="lucide:chevron-right" className="text-xl" />
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {currentPage} of {totalPages}
            </strong>
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="outline-none p-1 border-blue-100 border-b-2 border rounded-lg py-0.5"
          >
            {[10, 20, 30, 40, 50].map((page) => (
              <option key={page} value={page}>
                Show {page}
              </option>
            ))}
          </select>
        </div>
      </div>
      {showLocationAddModel && (
        <LocationAddModel
          open={showLocationAddModel}
          closeMemeberModel={() => setShowLocationAddModel(false)}
          addLocation={handleLocation}
          currentSociety={currentSociety}
        />
      )}
    </>
  );
};

export default SocietyList;
