/* eslint-disable no-sequences */
import { Button } from "@material-tailwind/react";
// import { mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import CreateLiveModal from "./CreateLiveModal";
import { motion } from "framer-motion";

import {
  changeLiveVideoVisibility,
  deleteLiveVideo,
  getLiveVideoList,
  updateLiveStreamPosition,
} from "./actionCreator";
import DeleteModal from "../../Common/DeleteModal/DeleteModal";
import { toastr } from "../../../services/common.service";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Icon } from "@iconify/react/dist/iconify.js";

const DraggableRow = ({ row, reorderRow, index }) => {
  const [, dropRef] = useDrop({
    accept: "row",
    drop: (draggedRow) => reorderRow(draggedRow.index, index),
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: "row",
  });

  return (
    <motion.tr
      ref={previewRef}
      initial={{ opacity: 1 }}
      animate={{ opacity: isDragging ? 0.5 : 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <td ref={dropRef} className="text-center">
        <button ref={dragRef} className="cursor-grab">
          <Icon icon="grommet-icons:drag" className="text-xl" />
        </button>
      </td>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} className="p-2">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </motion.tr>
  );
};

const columnHelper = createColumnHelper();

const Live = () => {
  const [open, setOpen] = useState(false);
  const [currentLiveVideo, setCurrentLiveDetail] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSabhaId, setDeleteSabhaId] = useState("");
  const [data, setData] = useState(() => []);

  const reorderRow = (draggedRowIndex, targetRowIndex) => {
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
    setData([...data]);
  };

  const publishLiveStream = async () => {
    let newList = [...data];
    let updatedItem = [];
    newList.map((item, index) =>
      updatedItem.push({ _id: item._id, position: index })
    );
    const result = await updateLiveStreamPosition({ list: updatedItem });
    if (result.data) {
      toastr.show(result.data, "success");
    }
  };
  const openCreateLinkModal = (_id = null) => {
    if (_id) {
      const currentDetail = data.find((live) => live._id + "" === _id + "");
      setCurrentLiveDetail(currentDetail);
    } else {
      setCurrentLiveDetail({});
    }
    setOpen(!open);
  };

  const getLiveList = async () => {
    const liveListData = await getLiveVideoList();
    setData(liveListData?.data || []);
  };

  const changeVisibility = async (status, id) => {
    const changeVisibility = await changeLiveVideoVisibility({
      visibility: status,
      _id: id,
    });
    if (changeVisibility.data) {
      toastr.show("Visibility Updated!", "success");
      const newLiveList = data.map((live) => {
        if (live._id + "" === id + "") {
          return { ...live, visibility: status };
        }
        return live;
      });
      setData(newLiveList);
    } else {
      toastr.show("Something went wrong", "error");
    }
  };

  const handleCloseModal = async (status) => {
    if (status) {
      const updatedData = await deleteLiveVideo(deleteSabhaId);
      if (updatedData?.data) {
        toastr.show("Deleted!!", "success");
        const newList = data?.filter(
          (live) => live._id + "" !== deleteSabhaId + ""
        );
        setData(newList);
      } else {
        toastr.show("Something went wrong", "error");
      }
    }
    setShowDeleteModal(false);
    setDeleteSabhaId("");
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteSabhaId(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const columns = [
    // eslint-disable-next-line no-unused-expressions
    columnHelper.accessor("thumbnailImages", {
      cell: (info) => (
        <img
          src={
            info.getValue().default
              ? info.getValue().default
              : "/assets/image/no-thumbnail.png"
          }
          alt="thumbnail"
          className="w-[150px] rounded-lg"
        />
      ),
      header: () => <span>Thumbnail</span>,
    }),
    columnHelper.accessor("title", {
      cell: (info) => info.getValue(),
      header: <span>Title</span>,
    }),
    columnHelper.accessor("visibility", {
      cell: (info) => (
        <Switch
          checked={info.getValue() || false}
          onChange={() =>
            changeVisibility(!info.getValue(), info.row.original._id)
          }
          className={`${
            info.getValue() ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              info.getValue() ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      ),
    }),
    columnHelper.display({
      header: () => <span className="text-center">Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span className="w-9 h-9 border border-gray400 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer hover:border-black900 group hover:text-black900">
              <button
                onClick={() => openCreateLinkModal(props.row.original._id)}
              >
                <Icon
                  icon="mingcute:pencil-fill"
                  className="text-gray400 transition-all duration-150 group-hover:text-black900"
                />
              </button>
            </span>
            <button className="w-9 h-9 border rounded-xl transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer border-black900 group text-black900">
              <Icon
                icon="material-symbols:delete"
                size={1}
                onClick={() => handleOpenDeleteModal(props.row.original._id)}
                className="transition-all duration-150 text-black900"
              />
            </button>
          </span>
        );
      },
    }),
  ];

  useEffect(() => {
    getLiveList();
  }, []);

  const table = useReactTable(
    {
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getRowId: (row) => row.id,
    },
    [data]
  );

  return (
    <>
      <div className="h-[calc(100vh_-_81px)]  2xl:p-[30px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Live Darshan</div>
          <Button
            color="blue"
            className="flex items-center min-w-[150px]"
            onClick={() => openCreateLinkModal()}
          >
            <Icon icon={"ic:baseline-plus"} size={1} className="mr-1" /> Create
            Live Darshan
          </Button>
        </div>

        <div className="bg-white w-full p-7 rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)] ">
          <div className="p-2 overflow-auto">
            <DndProvider backend={HTML5Backend}>
              <table className="w-full member-table table-auto">
                <thead>
                  {table.getHeaderGroups().map((headerGroup, indx) => {
                    return (
                      <tr
                        className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]"
                        key={indx}
                      >
                        <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className="text-left font-semibold p-2"
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "last:text-center",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                <tbody className="className='last:!border-b-0'">
                  {table.getRowModel().rows.map((row, index) => (
                    <DraggableRow
                      key={row.id}
                      row={row}
                      reorderRow={reorderRow}
                      index={index}
                    />
                  ))}
                </tbody>
              </table>
            </DndProvider>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-3 inline-flex justify-center uppercase rounded-md font-semibold bg-white px-4 py-2 text-sm  text-red-600 hover:text-red-600 focus:outline-none"
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex justify-center uppercase rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white hover:bg-primary focus:outline-none"
              onClick={() => publishLiveStream()}
            >
              Publish
            </button>
          </div>
        </div>
      </div>
      <DeleteModal open={showDeleteModal} handleCloseModal={handleCloseModal} />
      <CreateLiveModal
        open={open}
        setOpen={setOpen}
        setLiveList={setData}
        liveList={data}
        currentLiveVideo={currentLiveVideo}
      />
    </>
  );
};

export default Live;
