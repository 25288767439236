import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import lodash from "lodash";
import CreatableMultiselectMain from "../Common/Input/CreatableMultiselectMain";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import Textarea from "../Common/Input/Textarea";
import { mdiClose, mdiPlus } from "@mdi/js";
import { mdiDelete } from "@mdi/js";
import { mdiPencil } from "@mdi/js";
import { toastr } from "../../services/common.service";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { createStatus, updateStatus } from "./actionCreator";
// import { Icon } from "@iconify/react/dist/iconify.js";

const columnHelper = createColumnHelper();

const CreateStatus = ({ openStatusModal, setOpenStatusModal, setStatusData, statusData }) => {

  const [status, setStatus] = useState("")

  const _updateStatus = (id) => {
    updateStatus({_id: id, isDeleted: true }).then(() => {
      toastr.show("Successfully deleted!", "success");
      const newList = statusData?.filter((f) => f._id + "" !== id + "");
      setStatusData(newList);
    }).catch((e) => {
      toastr.show("Something went wrong!", "error");
    })
  }

  const _createStatus = () => {
    createStatus({statusName: status }).then((res) => {
      toastr.show("Successfully added!", "success");
      const newList = [...statusData, res.data]
      setStatusData(newList);
      setStatus("")
    }).catch((e) => {
      toastr.show("Something went wrong!", "error");
    })
  }


  const columns = [
    columnHelper.accessor("statusName", {
      cell: (info) => info.getValue(),
      header: () => <span>Status</span>,
      footer: (info) => info.column.id,
    }),

    // columnHelper.accessor("Index", {
    //   cell: (info) => info.getValue(),
    //   header: () => <span>Index</span>,
    //   id: "index",
    //   cell: (info) => {
    //     return (
    //       <input
    //         type="number"
    //         min={0}
    //         className="border-2 p-1 rounded-l w-2/3 border-gray-300"
    //         value={info.getValue()}
    //       />
    //     );
    //   },
    // }),

    columnHelper.display({
      cell: (info) => info.getValue(),
      header: () => <span>Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            {/* <span className="w-8 h-8 border border-gray400 rounded-lg transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer hover:border-black900 group hover:text-black900">
              <button>
                <Icon
                  path={mdiPencil}
                  size={0.8}
                  className="transition-all duration-150 group-hover:text-black900"
                />
              </button>
            </span> */}
            <button className="w-9 h-8 border border-gray400 rounded-md transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer hover:border-black900 group hover:text-black900">
              <Icon
                path={mdiDelete}
                size={1}
                className="transition-all duration-150 text-black900"
                onClick={() => _updateStatus(props.row.original._id)}
              />
            </button>
          </span>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: statusData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Transition appear show={openStatusModal} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      Create Status
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpenStatusModal(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex-grow flex justify-center">
                        <div className="max-w-md w-full">
                          <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                            <div className="flex">
                              <div className="mb-5">
                                <Input
                                  label="Enter Status"
                                  className=" w-full"
                                  onChange={(e) => setStatus(e.target.value)}
                                  value={status}
                                />
                              </div>
                              <button className="bg-[#007AFF] text-white px-3 py-2 rounded-[5px] ml-2 h-10">
                                <Icon path={mdiPlus} size={1} onClick={() => _createStatus()}/>
                              </button>
                            </div>

                            <div className="max-h-96 mb-5 overflow-auto">
                              <table className="w-full member-table table-auto">
                                <thead className="sticky top-0 z-10 border-b border-gray-300">
                                  {table
                                    .getHeaderGroups()
                                    .map((headerGroup) => (
                                      <tr
                                        key={headerGroup.id}
                                        className="text-sm bg-[#F2F2F2] text-[#535353]"
                                      >
                                        {headerGroup.headers.map((header) => (
                                          <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className="text-left font-semibold p-4 border border-gray-300"
                                          >
                                            {header.isPlaceholder ? null : (
                                              <>
                                                <div
                                                  {...{
                                                    className:
                                                      header.column.getCanSort()
                                                        ? "cursor-pointer select-none"
                                                        : "",
                                                    onClick:
                                                      header.column.getToggleSortingHandler(),
                                                  }}
                                                >
                                                  {flexRender(
                                                    header.column.columnDef
                                                      .header,
                                                    header.getContext()
                                                  )}
                                                </div>
                                              </>
                                            )}
                                          </th>
                                        ))}
                                      </tr>
                                    ))}
                                </thead>
                                <tbody className="last:!border-b-0">
                                  {table.getRowModel().rows.map((row) => {
                                    return (
                                      <tr
                                        key={row.id}
                                        className="border-b border-stone-200 text-sm"
                                      >
                                        {row.getVisibleCells().map((cell) => {
                                          return (
                                            <td
                                              key={cell.id}
                                              className="text-center px-2 py-4"
                                            >
                                              {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                              )}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CreateStatus;
