import React, { useEffect, useState } from "react";
import Icon from "@mdi/react";
import { mdiTrayArrowUp } from "@mdi/js";

import DailyDarshanGallery from "./DailyDarshanGallery";
import DailyDarshanUpload from "./DailyDarshanUpload";
import { toastr } from "../../services/common.service";
import {
  deleteDailyDarshan,
  getDailyDarshan,
  postDailyDarshan,
  uploadBothDarshan,
} from "./actionCreator";
import LoaderBar from "../Common/LoaderBar";

const DailyDarshan = () => {
  const [open, setOpen] = useState(false);
  const [lastActiveDarshan, setLastActiveDarshan] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const uploadFiles = async (files, darshanDate, imageType) => {
    try {
      if (!darshanDate) {
        toastr.show("Please Select Date", "error");
        return;
      }
      if (!files.length) {
        toastr.show("Please Select Files", "error");
        return;
      }
      setIsLoading(true);
      const response = await postDailyDarshan(files, darshanDate, imageType);
      if (response?.data) {
        toastr.show("Success !!", "success");
        setSelectedDate(null);
        setOpen(false);
      }
      let lastData = loadDailyDarshan()
      if (lastData) {
        setLastActiveDarshan(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading files:", error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  };

  const removeDailyDarshan = async (removeUrl, darshanDate, imageType) => {
    try {
      let activeUrls =
        imageType === "hd"
          ? [...lastActiveDarshan.fileHdUrls]
          : [...lastActiveDarshan.fileUrls];

      setIsLoading(true);
      const response = await deleteDailyDarshan(
        removeUrl,
        darshanDate,
        imageType
      );

      if (response.data) {
        toastr.show("Success !!", "success");
        let updatedUrls = activeUrls.filter((item) => item !== removeUrl);

        setLastActiveDarshan({
          ...lastActiveDarshan,
          [imageType === "hd" ? "fileHdUrls" : "fileUrls"]: updatedUrls,
        });
        setOpen(false);
      } else {
        toastr.show("Failed !!", "error");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting files:", error);
      toastr.show("Failed !!", "error");
      setIsLoading(false);
    }
  };

  const loadDailyDarshan = async () => {
    try {
      const response = await getDailyDarshan();
      if (response.data) {
        setLastActiveDarshan(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error Fetching Darshan:", error);
      setIsLoading(false);
    }
  };

  const openEditDarshanModal = (_selectedDate) => {
    setSelectedDate(_selectedDate);
    setOpen(true);
  };

  useEffect(() => {
    loadDailyDarshan();
  }, []);

  return (
    <>
      {isLoading && <LoaderBar />}
      <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b">
          <div className="text-[25px] text-[#212121]">Daily Darshan</div>
          <div className="flex items-center justify-between px-4">
            <button
              className="shadow-lg w-10 h-10 bg-white border p-1.5 rounded-full border-transparent flex items-center justify-center hover:opacity-100 transition-all duration-300 ease-[ease] hover:border-[black] hover:shadow-[0px_2px_4px_0px_#00000045]"
              onClick={() => {
                setOpen(!open);
                setSelectedDate(null);
              }}
            >
              <Icon path={mdiTrayArrowUp} size={0.9} />
            </button>
          </div>
        </div>
        {lastActiveDarshan && (
          <DailyDarshanGallery
            lastActiveDarshan={lastActiveDarshan}
            openEditDarshanModal={openEditDarshanModal}
            removeDailyDarshan={removeDailyDarshan}
            setLastActiveDarshan={setLastActiveDarshan}
          />
        )}
      </div>
      <DailyDarshanUpload
        open={open}
        setOpen={setOpen}
        uploadFiles={uploadFiles}
        selectedDate={selectedDate}
      />
    </>
  );
};

export default DailyDarshan;
