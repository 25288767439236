import React, { useState, useEffect } from "react";
import RequestAnswer from "./RequestAnswer";
import RequestTable from "./RequestTable";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button } from "@material-tailwind/react";
import CreateSubject from "./CreateSubject";
import CreateStatus from "./CreateStatus";
import DatePickerField from "../Common/Rsuite-Datepicker";
import SelectField from "../Common/Input/SelectField";
import { getStatus, getSubject } from "./actionCreator";

const RequestData = () => {
  const [isRequestAnswerModalOpen, setIsRequestAnswerModalOpen] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [filter, setFilter] = useState({});

  const openCreateSubjetModal = () => {
    setOpen(!open);
  };

  const openCreateStatusModal = () => {
    setOpenStatusModal(!openStatusModal);
  };

  const fetchStatusData = async () => {
    const data = await getStatus();
    setStatusData(data);
  };
  const fetchSubjectData = async () => {
    const data = await getSubject();
    setSubjectData(data);
  };

  useEffect(() => {
    fetchStatusData();
    fetchSubjectData();
  }, []);

  const defaultPriorityData = [
    {
      _id: "1",
      Priority: "1 (High)",
    },
    {
      _id: "2",
      Priority: "2 (Medium)",
    },
    {
      _id: "3",
      Priority: "3 (Low)",
    },
  ];

  return (
    <>
      <div className="h-[calc(100vh_-_81px)] 2xl:p-[20px] bg-[#F8F8F8] overflow-auto">
        <div className="text-[24px] text-[#212121]">Request</div>
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          {/* <input
            id="Subject"
            type="text"
            placeholder="Subject"
            autoComplete="off"
            className="bg-gray-200 text-sm h-9 w-64 text-black-900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
            onChange={(e) => e}
          /> */}

          <div className="flex gap-2 mt-2">
            <div>
              <SelectField
                isClearable={false}
                isMulti={false}
                placeholder="Select Status"
                defaultOptions={statusData.map((status) => ({
                  label: status.statusName,
                  value: status._id,
                }))}
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, status: e.label }));
                }}
              />
            </div>

            <div>
              <SelectField
                isClearable={false}
                isMulti={false}
                placeholder="Select Subject"
                defaultOptions={subjectData.map((subject) => ({
                  label: subject.subjectName,
                  value: subject._id,
                }))}
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, subject: e.label }));
                }}
              />
            </div>

            <div>
              <SelectField
                isClearable={false}
                isMulti={false}
                placeholder="Select Priority"
                defaultOptions={defaultPriorityData.map((priority) => ({
                  label: priority.Priority,
                  value: priority._id,
                }))}
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, priority: e.value }));
                }}
              />
            </div>

            <div>
              <DatePickerField
                cleanable={true}
                placeholder="Start Date"
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, startDate: e }));
                }}
              />
            </div>

            <div>
              <DatePickerField
                cleanable={true}
                placeholder="End Date"
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, endDate: e }));
                }}
              />
            </div>
          </div>

          <div className="flex gap-5">
            <Button
              color="blue"
              className="flex items-center min-w-[150px]"
              onClick={() => openCreateStatusModal()}
            >
              <Icon icon={"ic:baseline-plus"} size={1} className="mr-1" />{" "}
              Create Status
            </Button>

            <Button
              color="blue"
              className="flex items-center min-w-[150px]"
              onClick={() => openCreateSubjetModal()}
            >
              <Icon icon={"ic:baseline-plus"} size={1} className="mr-1" />{" "}
              Create Subject
            </Button>
          </div>
        </div>
        <div className="bg-white w-full p-7 rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)] ">
          <div className="p-2 overflow-auto">
            <RequestTable
              statusData={statusData}
              filter={filter}
              subjectData={subjectData}
            />
          </div>
        </div>
      </div>

      {isRequestAnswerModalOpen && (
        <RequestAnswer
          setIsRequestAnswerModalOpen={setIsRequestAnswerModalOpen}
        />
      )}

      <CreateSubject
        open={open}
        setOpen={setOpen}
        subjectData={subjectData}
        setSubjectData={setSubjectData}
      />

      <CreateStatus
        openStatusModal={openStatusModal}
        setOpenStatusModal={setOpenStatusModal}
        setStatusData={setStatusData}
        statusData={statusData}
      />
    </>
  );
};

export default RequestData;
