import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Icon from "@mdi/react";
import { mdiClose, mdiPencil, mdiDotsVertical, mdiDelete } from "@mdi/js";
import moment from "moment";
import SelectField from "../Common/Input/SelectField";
import {
  deleteMessageReply,
  editMessageReply,
  editRequest,
} from "./actionCreator";
import { toastr } from "../../services/common.service";

const ShowHistoryOfRequest = ({
  openHistory,
  setOpenHistory,
  data,
  requestId,
  sendReply,
  statusData,
  subjectData,
}) => {
  const [chatHistory, setChatHistory] = useState(data?.replies || []);

  const [reply, setReply] = useState("");
  const [selectedStatus, setStatus] = useState("");
  const [selectedSubject, setSubject] = useState(data.subject);

  useEffect(() => {
    setReply("");
  }, [data]);

  useEffect(() => {
    setChatHistory(data?.replies || []);
  }, [data]);

  const [dropdownOpen, setDropdownOpen] = useState(null);

  const [editSubject, setEditSubject] = useState(false);

  const [editingMessageId, setEditingMessageId] = useState(null);

  const handleDotsClick = (index) => {
    setDropdownOpen(dropdownOpen === index ? null : index);
  };

  const handleCloseInfoBox = () => {
    setDropdownOpen(null);
  };

  const handleEdit = (message) => {
    setDropdownOpen(null);
    editMessageReply(message._id, requestId, reply)
      .then(() => {
        setChatHistory((prevHistory) =>
          prevHistory.map((msg) =>
            msg._id === message._id ? { ...msg, message: reply } : msg
          )
        );
        setReply("");
        setEditingMessageId(null);
        toastr.show("Message edited successfully", "success");
      })
      .catch((error) => {
        toastr.show("Failed to edit message", "error");
      });
  };

  const handledelete = (message) => {
    setDropdownOpen(null);
    deleteMessageReply(message._id, requestId)
      .then(() => {
        setChatHistory((prevHistory) =>
          prevHistory.filter((msg) => msg._id !== message._id)
        );
        toastr.show("Message deleted successfully", "success");
      })
      .catch((error) => {
        toastr.show("Failed to delete message", "error");
      });
  };

  const _editRequest = (data) => {
    editRequest(data)
      .then(() => {
        setSubject(data.subject);
        toastr.show("Request edited successfully", "success");
      })
      .catch((error) => {
        toastr.show("Failed to edit request", "error");
      });
  };

  return (
    <>
      <Transition appear show={openHistory} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl h-[90vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray-400">
                    <div className="flex items-center text-lg ">
                      <div className="font-bold">Subject :</div>
                      {editSubject ? (
                        <div className="flex items-center gap-1">
                          <div className="ml-2 w-56">
                            <SelectField
                              isClearable={false}
                              isMulti={false}
                              placeholder="Select Subject"
                              defaultOptions={subjectData.map((subject) => ({
                                label: subject.subjectName,
                                value: subject._id,
                              }))}
                              value={{
                                label: selectedSubject,
                                value: selectedSubject,
                              }}
                              onChange={(e) => {
                                setSubject(e.label);
                                _editRequest({ requestId, subject: e.label });
                              }}
                            />
                          </div>

                          <span className="w-7 h-7 ml-2 border border-gray400 rounded-md transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer hover:border-red-400 group border-red-200 text-red-400">
                            <Icon
                              path={mdiClose}
                              size={1}
                              className="transition-all duration-150 group-hover:text-red-700"
                              onClick={() => {
                                setEditSubject(false);
                              }}
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <div className="font-semibold text-orange-600 ml-2">
                            {data?.subject}
                          </div>
                          <span className="w-7 h-7 ml-2 border border-gray400 rounded-md transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer hover:border-black900 group hover:text-black900">
                            <button onClick={() => setEditSubject(true)}>
                              <Icon
                                path={mdiPencil}
                                size={0.8}
                                className="transition-all duration-150 group-hover:text-black900"
                              />
                            </button>
                          </span>
                        </div>
                      )}
                    </div>

                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpenHistory(false);
                        setEditSubject(false);
                      }}
                    />
                  </div>
                  <div
                    className="p-6 space-y-4 max-h-[85%] overflow-y-auto"
                    ref={(el) => {
                      if (el) {
                        setTimeout(() => {
                          el.scrollTop = el.scrollHeight;
                        }, 100);
                      }
                    }}
                    onClick={() => handleCloseInfoBox()}
                  >
                    <div className={`flex justify-start`}>
                      <div
                        className={`bg-gray-100 text-gray-900 p-4 rounded-lg shadow-md max-w-md`}
                      >
                        <div className="space-y-4">
                          {data?.attachments?.map((attachment, index) => (
                            <div
                              key={index}
                              className="w-10 bg-gray-200 rounded-md"
                            >
                              <div className="relative">
                                <img
                                  src={attachment}
                                  alt="attachment"
                                  className="w-full h-full object-cover cursor-pointer"
                                  onClick={() => {
                                    window.open(
                                      attachment,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <div className="mb-4">
                            {data?.message && (
                              <div className="mt-2">
                                <p className="text-sm">{data.message}</p>
                              </div>
                            )}
                            {data?.description && (
                              <p className="text-sm">{data.discription}</p>
                            )}
                          </div>

                          <div className="flex gap-x-4 items-center text-[12px] text-gray-600">
                            {data?.name}
                            <div
                              className={`text-[12px] ml-auto text-gray-600`}
                            >
                              {moment(data?.repliedAt)
                                .utcOffset("+05:30")
                                .format("DD-MM-YYYY hh:mm A")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {chatHistory.map((chat, index) => (
                      <div
                        key={index}
                        className={`flex  ${
                          chat.reply ? "justify-end" : "justify-start"
                        }`}
                      >
                        <div
                          className={`${
                            chat.reply
                              ? "bg-blue-500 text-white min-w-[240px]"
                              : "bg-gray-100 text-gray-900"
                          } p-4 rounded-lg shadow-md max-w-xs`}
                        >
                          <div className="space-y-4">
                            {chat?.attachments?.map((attachment, index) => (
                              <div
                                key={index}
                                className="w-10 bg-gray-200 rounded-md"
                              >
                                <div className="relative">
                                  <img
                                    src={attachment}
                                    alt="attachment"
                                    className="w-full h-full object-cover cursor-pointer"
                                    onClick={() => {
                                      window.open(
                                        attachment,
                                        "_blank",
                                        "noopener,noreferrer"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="mb-4">
                            {chat.message && (
                              <div className="flex">
                                <p
                                  className="overflow-auto text-sm"
                                  style={{ maxHeight: "100px" }}
                                >
                                  {chat.message}
                                </p>
                                <div className="text-right ml-auto">
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDotsClick(index);
                                    }}
                                  >
                                    <Icon
                                      path={mdiDotsVertical}
                                      size={1}
                                      className="transition-all duration-150 group-hover:text-black900"
                                    />
                                  </button>

                                  {dropdownOpen === index && (
                                    <div
                                      className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 shadow-lg rounded-md z-50 shadow-lg"
                                      style={{ marginRight: "80px" }}
                                    >
                                      <button
                                        className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-gray-100 text-black"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setDropdownOpen(null);
                                          setReply(chat.message);
                                          setEditingMessageId(chat._id);
                                        }}
                                      >
                                        <Icon
                                          className="mr-2"
                                          path={mdiPencil}
                                          size={0.7}
                                        />
                                        Edit
                                      </button>
                                      <button
                                        className="flex items-center w-full text-left px-4 py-2 text-sm hover:bg-gray-100 text-black "
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handledelete(chat);
                                        }}
                                      >
                                        <Icon
                                          className="mr-2"
                                          path={mdiDelete}
                                          size={0.7}
                                        />
                                        Delete
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <p className="text-sm">{chat.discription}</p>
                          <div className="flex items-center text-xs text-black">
                            {chat.repliedBy}
                            <div
                              className={`text-xs ml-auto ${
                                chat.reply ? "text-black" : "text-gray-500"
                              }`}
                            >
                              {moment(chat.repliedAt)
                                .utcOffset("+05:30")
                                .format("DD-MM-YYYY hh:mm A")}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* here add one textaria for user to reply */}
                  <div className="mt-4 flex gap-2 items-start">
                    <div className="w-full">
                      <textarea
                        className="flex-1 p-2 border border-gray-300 rounded-md w-full"
                        placeholder="Write your reply here..."
                        onChange={(e) => setReply(e.target.value)}
                        value={reply}
                      />
                    </div>
                    <div className="ml-auto w-40">
                      <SelectField
                        isClearable={false}
                        isMulti={false}
                        placeholder="Change Status"
                        className=""
                        defaultOptions={statusData.map((status) => ({
                          label: status.statusName,
                          value: status.statusName,
                        }))}
                        value={{ label: selectedStatus, value: selectedStatus }}
                        onChange={(e) => {
                          setStatus(e.label);
                        }}
                      />
                    </div>
                    <div>
                      <button
                        disabled={!reply}
                        className={`text-white p-2 rounded-md ${
                          !reply
                            ? "cursor-not-allowed bg-blue-200"
                            : "bg-blue-500"
                        }`}
                        onClick={() => {
                          if (editingMessageId) {
                            handleEdit({ _id: editingMessageId });
                          } else {
                            sendReply(requestId, reply, selectedStatus);
                          }
                        }}
                      >
                        {editingMessageId ? "Update" : "Reply"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ShowHistoryOfRequest;
