import { ACTION_TYPES } from "../constants";

const INITIAL_STATE = {
    streetNameList: [],
    societyList: [],
    cityList: [],
    stateList: [],
    countryList: [],
    villageList: [],
    occupationList: [],
    educationList: [],
    saintList: [],
    societyIndexList: []
};

const getList = ({ state, FieldList }) => {
    return {
        ...state,
        streetNameList: FieldList.streetName.map((_val) => ({ label: _val, value: _val })),
        societyList: FieldList.society.map((_val) => ({ label: _val, value: _val })),
        cityList: FieldList.city.map((_val) => ({ label: _val, value: _val })),
        stateList: FieldList.state.map((_val) => ({ label: _val, value: _val })),
        countryList: FieldList.country.map((_val) => ({ label: _val, value: _val })),
        villageList: FieldList.village.map((_val) => ({ label: _val, value: _val })),
        occupationList: FieldList.occupation.map((_val) => ({ label: _val, value: _val })),
        educationList: FieldList.education.map((_val) => ({ label: _val, value: _val })),
        saintList: FieldList.saint,
        societyIndexList: FieldList.societyIndex.map((_val) => ({ label: _val, value: _val }))
    };
}

function commonFieldsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_COMMON_FIELDS: {
            const FieldList = action.payload;
            return getList({ state, FieldList });
        }
        default:
            return state;
    }
}

export default commonFieldsReducer;
