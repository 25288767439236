import { Tab } from "@headlessui/react";
import lodash from "lodash";
import React, { useEffect, useState } from "react";
import NewEntryList from "./NewEntryList";
import NewMember from "../attendance/add-member/NewMember";
import { Icon } from "@iconify/react";
import MemberProfile from "../attendance/memberlist/Member-profile/memberProfile";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNewMember,
  getNewMembersList,
  getSabhaTypes,
} from "./actionCreator";
import { ACTION_TYPES } from "../../redux/constants";
import { toastr } from "../../services/common.service";
import { updateNewMember } from "../attendance/add-member/actionCreator";
import { genderType } from "../../constants/common.constants";
import SelectField from "../Common/Input/SelectField";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Newentry = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { NewMemberList: NewMemberMasterList } = useSelector(
    (state) => state.NewMemberReducer
  );
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [collapseProfile, setCollapseProfile] = useState(false);
  const [memberDetails, setMemberDetails] = useState();
  const [sabhaTypes, setSabhaTypes] = useState([]);
  const [currentSabha, setCurrentSabha] = useState("6533aa855da1a84b9ad7a706");
  const [_filterData, setFilterData] = useState([]);
  const [selectedGender, setSelectedGender] = useState(genderType[0]);

  useEffect(() => {
    getSabhaTypesList();
    fetchAllMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      let _memberDetails = lodash.cloneDeep(NewMemberMasterList);
      if (currentSabha && currentSabha !== "") {
        _memberDetails = _memberDetails.filter(
          (item) => item?.sabhaId + "" === currentSabha + ""
        );
      } else {
        _memberDetails = _memberDetails.filter((item) => !item?.sabhaId);
      }
      _memberDetails = _memberDetails.filter(
        (item) => item?.gender.toLowerCase() === selectedGender.value
      );
      setFilterData(_memberDetails);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGender]);

  useEffect(() => {
    if (NewMemberMasterList && NewMemberMasterList.length > 0) {
      setData(NewMemberMasterList);
      filterData(currentSabha, NewMemberMasterList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NewMemberMasterList]);

  const getSabhaTypesList = async () => {
    try {
      const response = await getSabhaTypes();
      if (response?.data) {
        const types = response?.data.filter((type) => !type?.isDeleted);
        setSabhaTypes(types);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCollapseProfile = (memberData) => {
    if (memberDetails?._id === memberData?._id) {
      setCollapseProfile(!collapseProfile);
    } else {
      setMemberDetails(memberData);
      setCollapseProfile(true);
    }
  };

  const handleCloseProfile = () => {
    setCollapseProfile(false);
  };

  const _SetMemberDetails = (field, value) => {
    const _memberDetails = lodash.cloneDeep(memberDetails);
    if (["mobile", "mobile2", "referrerMobile"].includes(field)) {
      if (value.length > 0) {
        let mobileValue = Number(value);
        if (!mobileValue) return;
        else value = mobileValue;
      }
    }
    _memberDetails[field] = value;
    setMemberDetails(_memberDetails);
  };

  const fetchAllMembers = async () => {
    try {
      const response = await getNewMembersList();
      if (response?.data) {
        dispatch({
          type: ACTION_TYPES.SET_NEW_MEMBERS_LIST,
          payload: response.data,
        });
        setData(response.data);
        filterData(currentSabha);
      }
    } catch (err) {
      dispatch({ type: ACTION_TYPES.SET_NEW_MEMBERS_LIST, payload: [] });
      toastr.show("Fetching Members Failed.", "error");
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      if (id) {
        await deleteNewMember(id);
      }
      fetchAllMembers();
    } catch (err) {
      toastr.show("Failed to delete user", "error");
    }
  };

  // const filterData = (sabhaId, users = []) => {
  //     setCurrentSabha(sabhaId);
  //     let userData = users?.length > 0 ? users : data;
  //     if (!lodash.isEmpty(sabhaId)) {
  //         userData = userData.filter(user => user?.sabhaId + "" === sabhaId + "");
  //     } else {
  //         userData = userData.filter(user => !user?.sabhaId);
  //     }
  //     setFilterData(userData);
  // }

  const filterData = (sabhaId, users = []) => {
    setCurrentSabha(sabhaId);
    let userData = users?.length > 0 ? users : data;

    // Filter data based on Sabha ID
    if (!lodash.isEmpty(sabhaId)) {
      userData = userData.filter((user) => user?.sabhaId + "" === sabhaId + "");
    } else {
      userData = userData.filter((user) => !user?.sabhaId);
    }

    // Filter data based on selected gender
    userData = userData.filter(
      (user) => user.gender.toLowerCase() === selectedGender.value
    );

    setFilterData(userData);
  };

  const updateMember = async () => {
    try {
      memberDetails.address = {
        blockNo: memberDetails.blockNo || memberDetails?.address?.blockNo,
        streetName:
          memberDetails.streetName || memberDetails?.address?.streetName,
        society: memberDetails.society || memberDetails?.address?.society,
        landmark: memberDetails.landmark || memberDetails?.address?.landmark,
        city: memberDetails.city || memberDetails?.address?.city,
        state: memberDetails.state || memberDetails?.address?.state,
        postalCode:
          memberDetails.postalCode || memberDetails?.address?.postalCode,
        country: memberDetails.country || memberDetails?.address?.country,
      };
      memberDetails.attendeeOf =
        memberDetails.attendeeOf?.filter(
          (sabha) => sabha?.sabhaId && sabha?.sabhaName && sabha?.groupNo
        ) || [];

      const userData = lodash.cloneDeep(memberDetails);
      userData.mobile = userData.mobile.toString();
      if (userData.mobile2) userData.mobile2 = userData.mobile2.toString();
      if (userData.referrerMobile)
        userData.referrerMobile = userData.referrerMobile.toString();
      userData._id = memberDetails._id;
      let updateData = await updateNewMember(userData);
      dispatch({
        type: ACTION_TYPES.UPDATE_NEW_MEMBER,
        payload: updateData.data,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleGenderChange = (option) => {
    setSelectedGender(option);
  };
  return (
    <>
      <div>
        <div className="border-b border-stone-200 py-1.5 px-3 flex items-center justify-between">
          <div className="w-80 relative px-4">
            <input
              id="search-members"
              type="text"
              placeholder="Search Member"
              autoComplete="off"
              className="bg-gray200 text-sm h-9 w-full text-black900 outline-none px-4 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
              // value={_memberFilters?.searchTerm}
              // onChange={(e) => { setMemberFilters('searchTerm', e.target.value) }}
            />
            <Icon
              icon="iconamoon:search-light"
              className="absolute right-8 top-[50%] translate-y-[-50%] text-lg text-[#969696]"
            />
          </div>
          {/* <ColumnVisibilityMenu table={tableData} /> */}
          <div className="px-3 py-1">
            <button
              className="inline-flex items-center bg-primary text-white text-sm px-2 py-2 rounded-lg"
              onClick={() => setIsAddMemberModalOpen(true)}
            >
              <Icon icon="ic:baseline-plus" className="inline mr-1" />
              Add New Member
            </button>
          </div>
        </div>
      </div>
      <div className="w-full ">
        <div className="flex h-[calc(100vh_-_135px)] w-full">
          <div className="flex-grow overflow-auto ">
            <div className="flex-grow w-full p-3 bg-white overflow-y-auto ">
              <div className="w-full px-2 sm:px-0 h-full flex flex-col ">
                <Tab.Group>
                  <div
                    className={`flex flex-wrap px-4 gap-x-14 2xl:gap-x-10 items-center`}
                  >
                    <Tab.List
                      className={`flex space-x-2 rounded-xl bg-white p-2 border-blue-gray-50 border drop-shadow-md`}
                    >
                      {sabhaTypes &&
                        sabhaTypes?.map((sabha, index) => {
                          return (
                            <Tab
                              className={({ selected }) =>
                                classNames(
                                  "w-[150px] rounded-lg py-2.5 px-2 whitespace-pre text-sm font-medium leading-5",
                                  "ring-white/60 focus:outline-none",
                                  currentSabha + "" === sabha._id + ""
                                    ? "bg-primary text-white shadow-2xl"
                                    : "bg-white text-primary"
                                )
                              }
                              key={index}
                              onClick={(e) => filterData(sabha._id)}
                            >
                              {sabha?.name}
                            </Tab>
                          );
                        })}
                      <Tab
                        className={({ selected }) =>
                          classNames(
                            "w-[150px] rounded-lg py-2.5 text-sm font-medium leading-5",
                            "ring-white/60 focus:outline-none",
                            !currentSabha
                              ? "bg-primary text-white shadow-2xl"
                              : "bg-white text-primary"
                          )
                        }
                        onClick={(e) => filterData("")}
                      >
                        Other
                      </Tab>
                    </Tab.List>
                    <div className="relative z-40">
                      <SelectField
                        className="!h-9 placeholder:text-black900"
                        required={false}
                        placeholder="Select Gender"
                        isMulti={false}
                        defaultOptions={genderType}
                        value={selectedGender}
                        onChange={handleGenderChange}
                      />
                    </div>
                  </div>
                  <Tab.Panels className="mt-2 flex-grow">
                    {sabhaTypes?.map((sabha, index) => {
                      return (
                        <Tab.Panel className="h-full outline-none" key={index}>
                          <div className="h-full">
                            <NewEntryList
                              handleCollapsePofile={handleCollapseProfile}
                              data={_filterData}
                              currentSabha={currentSabha}
                              handleDeleteUser={handleDeleteUser}
                            />
                          </div>
                        </Tab.Panel>
                      );
                    })}

                    <Tab.Panel className="h-full outline-none">
                      <div className="h-full">
                        <NewEntryList
                          handleCollapsePofile={handleCollapseProfile}
                          data={_filterData}
                          currentSabha={currentSabha}
                          handleDeleteUser={handleDeleteUser}
                        />
                      </div>
                    </Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
          </div>
          <div
            className={`max-w-[450px] 2xl:max-w-[600px] shadow-md w-full relative overflow-auto transition-all ease-out duration-100' ${
              collapseProfile ? "mr-0" : "-mr-[450px] 2xl:-mr-[600px]"
            }`}
          >
            <MemberProfile
              profileData={memberDetails}
              setMemberDetails={_SetMemberDetails}
              updateMember={updateMember}
              isNewUser={true}
            />
            <div className="absolute right-4 top-4">
              <div className="flex items-center">
                <Icon
                  icon="streamline:move-right-solid"
                  className="cursor-pointer text-primary"
                  onClick={() => handleCloseProfile(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAddMemberModalOpen && (
        <NewMember
          setIsAddMemberModalOpen={setIsAddMemberModalOpen}
          isNewUser={true}
        />
      )}
    </>
  );
};

export default Newentry;
