import { useRoutes } from "react-router-dom"
import { ManagePadharamani, SevaList } from "../../../Components"
import SevaListIndex from "../../../Components/seva/SevaListIndex"

export default function PadharamaniRouter() {
    const padharamaniRouter = [

        {
            path: "/manage/*",
            element: (
                <>
                    <ManagePadharamani />
                </>
            )
        }
    ]

    const PadharamaniRouter = useRoutes(padharamaniRouter)

    return PadharamaniRouter
}