import React, { useState, useEffect, useMemo } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useParams } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { Button } from "@material-tailwind/react";
import { useReactNavigator } from "../../utils/reactNavigator";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { motion } from "framer-motion";
import { toastr } from "../../services/common.service";
import { Switch } from "@headlessui/react";
import { deleteDepartment, getSevaDetail } from "./actionCreator";
import AlertModal from "../Common/CustomConfirmationModal/CustomConfirmationModal";

const DraggableRow = ({ row, reorderSeva, onClick }) => {
    const [, dropRef] = useDrop({
        accept: "row",
        drop: (draggedRow) => reorderSeva(draggedRow.index, row.index),
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: "row",
    });

    return (
        <motion.tr
            ref={previewRef}
            initial={{ opacity: 1 }}
            animate={{ opacity: isDragging ? 0.5 : 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="border-b border-gray-200 cursor-pointer"
            onClick={onClick}
        >
            <td ref={dropRef} className="text-start w-[50px]">
                <button ref={dragRef} className="cursor-grab">
                    <Icon icon="grommet-icons:drag" className="text-xl" />
                </button>
            </td>
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </motion.tr>
    );
};
const DeptTable = ({ data, setData, sevaDetail, openDeptFormLink, fetchSevaDetail, sevaId, parentId }) => {
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [currentIndexId, setCurrentIndexId] = useState("");
    const { navigate } = useReactNavigator();

    const deleteDepartmentConfirmation = async (id) => {
        setShowConfirmationModel(!showConfirmationModel);
        setCurrentIndexId(id);
    };

    const handleDeleteDepartment = async (status) => {
        if (status) {
            try {
                if (currentIndexId) {
                    await deleteDepartment({ _id: currentIndexId });
                    await fetchSevaDetail(parentId, sevaId);
                    toastr.show("Department Deleted Successfully", "success");
                }
            } catch (err) {
                toastr.show("Something went wrong", "error");
            }
        }
        setShowConfirmationModel(false);
    }



    const changeVisibility = async (sevaId, status) => {
        try {
            //   const response = await changeSevaStatus(sevaId, status);
            //   if (response) {
            //     toastr.show("Seva status changed successfully!", "success");
            //     getAllSevaDetail();
            //   }
        } catch (err) {
            console.error("Error changing seva status:", err);
        }
    };

    const reorderSeva = (draggedRowIndex, targetRowIndex) => {
        data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
        setData([...data]);
        const indexIds = data.map((d) => d._id);
    };

    const handleNavigate = (row) => {
        navigate(`${row.original?._id}`, {
            parentDetail: row.original,
            sevaDetail,
        });
    }

    const columns = useMemo(() => [
        {
            accessorKey: "deptName",
            header: () => <span>Department Name</span>,
            cell: (info) => info.getValue(),
            id: "deptName",
            // size: 450,
        },
        {
            accessorKey: "sevakCount",
            header: () => <span>Total Sevak</span>,
            cell: (info) => info.getValue(),
            id: "sevakCount",
            // size: 450,
        },
        {
            header: () => <span className="text-center"></span>,
            id: "actions",
            cell: (props) => {
                return (
                    <span className="flex items-center justify-end">
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
                            <button onClick={(e) => { e.stopPropagation(); openDeptFormLink(props.row.original?._id) }}>
                                <Icon
                                    icon="ic:baseline-edit"
                                    className="text-2xl transition-all duration-150 text-black900"

                                />
                            </button>
                        </span>
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
                            <button onClick={(e) => { e.stopPropagation(); deleteDepartmentConfirmation(props.row.original?._id) }}>
                                <Icon
                                    icon="material-symbols:delete"
                                    className="transition-all duration-150 text-2xl text-black900"

                                />
                            </button>
                        </span>
                    </span>
                );
            },
        },
    ]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (row) => row.id,
    });

    return (
        <>
            <div>
                <div className="flex items-center mb-3">
                    <div className="text-xl font-medium">Department</div>
                </div>
                <div className="overflow-auto">
                    <DndProvider backend={HTML5Backend}>
                        <table className="w-full">
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => {
                                    return (
                                        <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                                            <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                                            {headerGroup.headers.map((header) => {
                                                return (
                                                    <th
                                                        key={header.id}
                                                        colSpan={header.colSpan}
                                                        className="text-left font-semibold p-2"
                                                    >
                                                        {header.isPlaceholder ? null : (
                                                            <div
                                                                {...{
                                                                    className: header.column.getCanSort()
                                                                        ? "cursor-pointer select-none"
                                                                        : "last:text-center",
                                                                }}
                                                            >
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                            </div>
                                                        )}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </thead>
                            <tbody className="last:!border-b-0">
                                {table.getRowModel().rows.map((row) => (
                                    <DraggableRow
                                        key={row.id}
                                        row={row}
                                        reorderSeva={reorderSeva}
                                        onClick={() => handleNavigate(row)}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </DndProvider>
                </div>
            </div>
            <AlertModal
                open={showConfirmationModel}
                handleCloseModal={handleDeleteDepartment}
                messageText="Are you sure you want to delete this Department?"
                submitBtnText="Confirm"
                buttonType="primary"
                icon="icon-park:go-end"
            />
        </>
    );
};

export default DeptTable;