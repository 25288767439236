import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose } from "@mdi/js";
import lodash, { set } from "lodash";
import { toastr } from "../../services/common.service";
// import { createSeva, updateSeva } from "./actionCreator";
import { createSeva, updateSeva } from "./actionCreator";
import DatePickerField from "../Common/Rsuite-Datepicker";
import moment from "moment";

const AddSevaModel = ({
  open,
  setOpen,
  currentSevaDetail,
  getAllSevaDetail,
}) => {
  const [sevaDetail, setSevaDetail] = useState(currentSevaDetail);
  const [enabled, setEnabled] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment(currentSevaDetail?.fromDate).startOf("day").toISOString()
  );
  const [toDate, setToDate] = useState(
    moment(currentSevaDetail?.toDate).startOf("day").toISOString()
  );
  const [isPadhramni, setIsPadhramni] = useState(false)
  // const [imageFile, setImageFile] = useState("");
  // const [imagePreviewFile, setImagePreviewFile] = useState("");

  const setCurrentDetail = (key, value) => {
    const newSevaDetail = lodash.cloneDeep(sevaDetail);
    newSevaDetail[key] = value;
    setSevaDetail(newSevaDetail);
  };

  // const handleImageFileChange = (vfile) => {
  //   const file = vfile?.[0];
  //   if (file) {
  //     const fileExtension = file?.name.substring(
  //       file?.name.lastIndexOf(".") + 1,
  //       file.name.length
  //     );
  //     const validataionMessage = "Please choose correct image format";
  //     if (file?.name?.length == 0) {
  //       setImageFile("");
  //       // toastr.show(validataionMessage);
  //       return;
  //     }
  //     if (
  //       fileExtension === "jpg" ||
  //       fileExtension === "webp" ||
  //       fileExtension === "png" ||
  //       fileExtension === "jpeg" ||
  //       fileExtension === "gif" ||
  //       fileExtension === "bmp"
  //     ) {
  //       setImageFile(vfile);
  //       // preview image
  //       const reader = new FileReader();
  //       reader.onload = (e) => {
  //         setImagePreviewFile(e?.target?.result);
  //       };
  //       reader.readAsDataURL(file);
  //     } else {
  //       setImageFile("");
  //       // toastr.show(validataionMessage);
  //       return;
  //     }
  //   }
  // };

  const resetForm = () => {
    setOpen(false);
    setCurrentDetail({});
    setSevaDetail({});
    setEnabled(false);
    // setImageFile("");
    // setImagePreviewFile("");
  };

  const addNewSevaInfo = async () => {
    try {
      if (sevaDetail?.["title"]?.length === 0)
        return toastr.show("Please Enter Title", "error");
      if (fromDate?.length === 0)
        return toastr.show("Please Enter From Date", "error");
      if (toDate?.length === 0)
        return toastr.show("Please Enter To Date", "error");
      // if (!imageFile?.[0]) return toastr.show("Please Choose Image", "error");

      const formData = {
        _id: sevaDetail?._id,
        title: sevaDetail?.["title"],
        enabled: enabled,
        fromDate: fromDate,
        toDate: toDate,
        isPadhramni: sevaDetail?.["isPadhramni"],
        order: sevaDetail?.["order"],
      }

      await createSeva(formData);
      getAllSevaDetail();
      setOpen(false);
    } catch (err) {
      console.error("Error uploading files:", err);
    }
  };

  const updateSevaInfo = async () => {
    try {
      try {
        if (sevaDetail?.["title"]?.length === 0)
          return toastr.show("Please Enter Title", "error");
        const formData = {
          _id: sevaDetail?._id,
          title: sevaDetail?.["title"],
          enabled: enabled,
          fromDate: fromDate,
          toDate: toDate,
          isPadhramni: sevaDetail?.["isPadhramni"],
          order: sevaDetail?.["order"],
        }

        await updateSeva(formData);
        getAllSevaDetail();
        setOpen(false);
      } catch (err) {
        console.error("Error uploading files:", err);
      }
    } catch (err) {
      console.error("Error uploading files:", err);
    }
  };

  useEffect(() => {
    if (currentSevaDetail?.title) {
      setSevaDetail(currentSevaDetail);
      // if (currentSevaDetail?.imageURL)
      //   setImagePreviewFile(`${s3Url}${currentSevaDetail?.imageURL}`);
      setEnabled(currentSevaDetail?.enabled);
      setIsPadhramni(currentSevaDetail?.isPadhramni);
    }
  }, [currentSevaDetail]);

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => { }}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="flex justify-between pb-4 border-b border-gray400">
                    <div className="text-lg font-semibold text-orange-600">
                      Add Seva
                    </div>
                    <Icon
                      path={mdiClose}
                      size={1}
                      className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex flex-col">
                      <div className="flex flex-col">
                        <div className="flex-grow flex justify-center">
                          <div className="max-w-md w-full">
                            <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                              <div>
                                <div className="mb-5">
                                  <div className="mb-2 text-lg">Title</div>
                                  <Input
                                    label="Title"
                                    className=" w-full"
                                    value={sevaDetail?.["title"] || ""}
                                    onChange={(e) =>
                                      setCurrentDetail("title", e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="mb-5">
                                <div className="mb-2 text-lg">From Date</div>
                                <DatePickerField
                                  placeholder="From Date"
                                  value={
                                    fromDate
                                      ? new Date(fromDate)
                                      : ""
                                  }
                                  onChange={(_date) =>
                                    setFromDate(
                                      moment(_date)
                                        .startOf("day")
                                        .toISOString()
                                    )
                                  }
                                />
                              </div>
                              <div className="mb-5">
                                <div className="mb-2 text-lg">To Date</div>
                                <DatePickerField
                                  placeholder="To Date"
                                  value={
                                    toDate
                                      ? new Date(toDate)
                                      : ""
                                  }
                                  onChange={(_date) =>
                                    setToDate(
                                      moment(_date)
                                        .startOf("day")
                                        .toISOString()
                                    )
                                  }
                                />
                              </div>
                              <div class="flex item-center mb-5">
                                <div className="flex items-center">
                                  <div className="mr-3  text-lg">
                                    Active
                                  </div>
                                  <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={`${enabled ? "bg-blue-600" : "bg-gray-200"
                                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                                  >
                                    <span className="sr-only">
                                      Enable notifications
                                    </span>
                                    <span
                                      className={`${enabled
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                  </Switch>
                                </div>
                                <div className="flex items-center ml-3">
                                  <div className="mr-3  text-lg">
                                    Is Padhramni
                                  </div>
                                  <Switch
                                    checked={isPadhramni}
                                    onChange={setIsPadhramni}
                                    className={`${isPadhramni ? "bg-blue-600" : "bg-gray-200"
                                      } relative inline-flex h-6 w-11 items-center rounded-full`}
                                  >
                                    <span className="sr-only">
                                      Enable notifications
                                    </span>
                                    <span
                                      className={`${isPadhramni
                                        ? "translate-x-6"
                                        : "translate-x-1"
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                  </Switch>
                                </div>
                              </div>
                              <div>
                                <div className="mb-2 text-lg">Order</div>
                                <Input
                                  label="Order"
                                  className=" w-full"
                                  value={sevaDetail?.["order"] || ""}
                                  onChange={(e) =>
                                    setCurrentDetail("order", e.target.value)
                                  }
                                // onInput={(e) => {
                                //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                // }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-right flex justify-end mt-6">
                      <div className="mr-5">
                        <Button
                          color="red"
                          variant="outlined"
                          className="capitalize text-base"
                          onClick={() => resetForm()}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="capitalize text-base bg-orange-600"
                          onClick={() =>
                            currentSevaDetail?._id
                              ? updateSevaInfo()
                              : addNewSevaInfo()
                          }
                        // disabled={isLoading}
                        >
                          {currentSevaDetail?._id ? "Update" : "Create"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddSevaModel;
