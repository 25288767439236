import { ACTION_TYPES } from "../constants";
import lodash from "lodash";


const INITIAL_STATE = {
    UsersData: [],
    FullNameList: []

};

const getUserList = ({ state, UsersData }) => {
    const fullNameList = lodash.uniq(
        UsersData.map((d) => {
            const fullName = `${d.firstName} ${d.middleName} ${d.lastName} | ${d.mobile}`;
            return fullName
        })
    ).filter((d) => d);
    return { ...state, UsersData: UsersData, FullNameList: fullNameList.map((_val) => ({ label: _val, value: _val })) };
}
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_USER: {
            const UsersData = action.payload;
            return getUserList({ state, UsersData: UsersData });
        }

    }
    return state;
};
