/* eslint-disable no-sequences */
import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { toastr } from "../../../services/common.service";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Icon } from "@iconify/react/dist/iconify.js";
import UpdateMemberOfName from "./updateMemberOfName";
import { changeFlagVisibility, getAllFlags } from "./ActionCreater";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ACTION_TYPES } from "../../../redux/constants";
import AlertModal from "../../Common/CustomConfirmationModal/CustomConfirmationModal";

const columnHelper = createColumnHelper();

const MemberOf = () => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [currentFlag, setCurrentFlag] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSabhaId, setDeleteSabhaId] = useState("");
  const { flagList } = useSelector((state) => state.MemberFlagsReducer);
  const [data, setData] = useState(flagList);
  const [showAlertModel, setShowAlertModel] = useState(false);

  const openCreateLinkModal = (_id = null) => {
    if (_id) {
      const currentDetail = data.find((live) => live._id + "" === _id + "");
      setCurrentFlag(currentDetail);
    } else {
      setCurrentFlag({});
    }
    setOpen(!open);
  };

  const openReasetFlagModel = async (_id = null) => {
    if (_id) {
      setShowAlertModel(true);
      const currentDetail = data.find((live) => live._id + "" === _id + "");
      setCurrentFlag(currentDetail);
    } else {
      setShowAlertModel(false);
      setCurrentFlag({});
    }
  };

  const resetUserConfirmation = async (status) => {
    if (status) {
      try {
        const id = currentFlag._id;
        if (id) {
          await changeFlagVisibility({
            isReset: true,
            _id: id,
          });
          toastr.show("Reset flag details!", "success");
        }
      } catch (err) {
        toastr.show("Something went wrong", "error");
      }
    }
    setShowAlertModel(false);
    setCurrentFlag({});
  };

  const changeVisibility = async (status, id) => {
    const changeVisibility = await changeFlagVisibility({
      visibility: status,
      _id: id,
    });
    if (changeVisibility.data) {
      toastr.show("Visibility Updated!", "success");
      const newLiveList = data.map((live) => {
        if (live._id + "" === id + "") {
          return { ...live, isShow: status };
        }
        return live;
      });
      dispatch({ type: ACTION_TYPES.SET_MEMBER_FLAG, payload: newLiveList });
      setData(newLiveList);
    } else {
      toastr.show("Something went wrong", "error");
    }
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteSabhaId(id);
    setShowDeleteModal(!showDeleteModal);
  };

  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: <span>Title</span>,
    }),
    columnHelper.accessor("isShow", {
      cell: (info) => (
        <Switch
          checked={info.getValue() || false}
          onChange={() =>
            changeVisibility(!info.getValue(), info.row.original._id)
          }
          className={`${
            info.getValue() ? "bg-blue-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable notifications</span>
          <span
            className={`${
              info.getValue() ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      ),
      header: <span>Enabled</span>,
    }),
    columnHelper.display({
      header: () => <span className="text-center">Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span className="w-9 h-9 border border-gray400 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer hover:border-black900 group hover:text-black900">
              <button
                onClick={() => openCreateLinkModal(props.row.original._id)}
              >
                <Icon
                  icon="mingcute:pencil-fill"
                  className="text-gray400 transition-all duration-150 group-hover:text-black900"
                />
              </button>
            </span>
            <button className="w-9 h-9 border rounded-xl transition-all duration-150 flex justify-center items-center mr-1 cursor-pointer border-black900 group text-black900">
              <Icon
                icon="material-symbols:refresh"
                size={1}
                onClick={() => openReasetFlagModel(props.row.original._id)}
                className="transition-all duration-150 text-black900"
              />
            </button>
          </span>
        );
      },
    }),
  ];

  const table = useReactTable(
    {
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getRowId: (row) => row.id,
    },
    [data]
  );

  return (
    <>
      <div className="h-[calc(100vh_-_81px)] 2xl:p-[20px] bg-[#F8F8F8] overflow-auto">
        <div className=" mx-auto pb-10">
          <div className="flex items-center justify-between pb-[15px] mb-[20px] m-2 border-b-[#D7D7D7] border-b border-solid">
            <div className="text-[25px] text-[#212121]">Member Of.</div>
          </div>

          <div className="bg-white w-full p-7 rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)] ">
            <div className="p-2 overflow-auto">
              <table className="w-full member-table table-auto">
                <thead>
                  {table.getHeaderGroups().map((headerGroup, indx) => {
                    return (
                      <tr
                        className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]"
                        key={indx}
                      >
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className="text-left font-semibold p-2"
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? "cursor-pointer select-none"
                                      : "last:text-center",
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>

                <tbody className="className='last:!border-b-0'">
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className="border-b border-stone-200 text-sm bg-white"
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="p-2">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <UpdateMemberOfName
        open={open}
        setOpen={setOpen}
        setLiveList={setData}
        liveList={data}
        currentFlag={currentFlag}
      />
      <AlertModal
        open={showAlertModel}
        handleCloseModal={resetUserConfirmation}
        messageText="Are you sure you want to reset user setting?"
        submitBtnText="Confirm"
        buttonType="primary"
        icon="icon-park:go-end"
      />
    </>
  );
};

export default MemberOf;
