exports.CONFIGURABLE_PERMISSIONS = {
  MANAGE_USERS: {
    title: "Manage Users",
    shortCode: "MU",
  },
  MANAGE_USERS_FULL: {
    title: "Manage Users (Admin)",
    shortCode: "MUF",
  },
  EDIT_GROUP: {
    title: "Edit Group",
    shortCode: "EG"
  },
  MANAGE_DARSHAN: {
    title: "Darshan & Events",
    shortCode: "DE",
  },
  MANAGE_LIVE: {
    title: "Live Katha",
    shortCode: "LK",
  },
  MANAGE_SABHA: {
    title: "Manage Sabha",
    shortCode: "MS",
  },
  MANAGE_ATTENDENCE: {
    title: "Manage Attendence",
    shortCode: "MA",
  },
  MANAGE_NEW_ENTRY: {
    title: "Manage New Entries",
    shortCode: "MN"
  },
  MANTRA_LEKHAN: {
    title: "Mantra Lekhan",
    shortCode: "ML"
  },
  MANAGE_FORM: {
    title: "Manage Form",
    shortCode: "MF"
  },
  MANAGE_SOCIETY: {
    title: "Manage Society",
    shortCode: "MS"
  },
  SOCIETY_PDF: {
    title: "Society PDF",
    shortCode: "SP"
  }
};
