import React, { useEffect, useState } from "react";

import {
  createColumnHelper,
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
} from "@tanstack/react-table";

import { Icon } from "@iconify/react";
import ShowHistoryOfRequest from "./ShowHistoryOfRequest";

import {
  getAllPendingRequests,
  sendMessageReply,
  setRequestPriority,
} from "./actionCreator";
import moment from "moment";
import { toastr } from "../../services/common.service";
import SelectField from "../Common/Input/SelectField";

function RequestTable({ statusData, filter, subjectData }) {
  const columnHelper = createColumnHelper();
  const [openHistory, setOpenHistory] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRequestCount, setTotalRequestCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [requestId, setRequestId] = useState(null);
  const [requestHistory, setRequestHistory] = useState(null);
  const [disablePaginationButton, setDisablePaginationButton] = useState(false);
  const openShowHistoryModal = (data) => {
    setOpenHistory(!openHistory);
    setRequestId(data?._id);
    setRequestHistory(data);
  };

  const defaultPriorityData = [
    {
      _id: "1",
      Priority: "1 (High)",
    },
    {
      _id: "2",
      Priority: "2 (Medium)",
    },
    {
      _id: "3",
      Priority: "3 (Low)",
    },
  ];

  const sendReply = async (requestId, reply, selectedStatus) => {
    const response = await sendMessageReply(requestId, reply, selectedStatus);
    if (response.data) {
      const req = requestData.find((r) => r._id + "" === requestId + "");
      req.replies = response.data.replies;

      requestData.forEach((r) => {
        if (r._id + "" === requestId + "") {
          r.replies = response.data.replies;
          r.status = response.data.status;
        }
      });
      setRequestData(requestData);
      setRequestHistory(response.data);
      toastr.show("Reply sent successfully", "success");
    }
  };

  const columns = [
    columnHelper.accessor("subject", {
      header: () => <span>Subject</span>,
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("SMVNo", {
      cell: (info) => info.getValue(),
      header: () => <span>SMV No.</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("name", {
      header: () => <span>Requester</span>,
      cell: (info) => info.renderValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("mobileNo", {
      cell: (info) => info.getValue(),
      header: () => <span>Mobile No.</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("AnsweredBy", {
      header: () => <span>Answered By</span>,
      cell: (info) => {
        const replies = info.row.original?.replies;
        return (
          <>
            {replies?.[replies.length - 1]?.reply &&
            replies?.[replies.length - 1]?.repliedBy
              ? replies?.[replies.length - 1]?.repliedBy
              : "-"}
          </>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.display({
      cell: (info) => info.getValue(),
      header: () => <span>Reply</span>,
      id: "history",
      cell: (info) => {
        return (
          <span className="w-9 h-9 border border-gray400 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer hover:border-black900 group hover:text-black900">
            <button>
              <Icon
                icon="mdi:eye"
                size={1}
                className="transition-all duration-150 group-hover:text-black900"
                onClick={() => openShowHistoryModal(info.row.original)}
              />
            </button>
          </span>
        );
      },
    }),
    // columnHelper.accessor("status", {
    //   header: () => <span>Status</span>,
    //   cell: (info) => info.renderValue(),
    //   footer: (info) => info.column.id,
    // }),
    columnHelper.accessor("status", {
      header: () => <span>Status</span>,
      cell: (info) => {
        const status = info.getValue();
        const statusColorMap = {
          Pending: "bg-yellow-100 text-yellow-800",
          Completed: "bg-green-100 text-green-800",
          Cancelled: "bg-red-100 text-red-800",
          Sent: "bg-blue-100 text-blue-800",
        };
        const statusClass =
          statusColorMap[status] || "bg-gray-100 text-gray-800";

        return (
          <span
            className={`px-2 py-1 rounded text-xs font-medium ${statusClass}`}
          >
            {status || "Unknown"}
          </span>
        );
      },
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor("Time", {
      header: () => <span>Date</span>,
      cell: (info) => {
        const replies = info.row.original?.replies;
        return (
          <>
            {moment(replies?.[replies.length - 1]?.repliedAt)
              .utcOffset("+05:30")
              .format("DD-MM-YYYY hh:mm A")}
          </>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("priority", {
      header: () => <span>Priority</span>,
      cell: (info) => {
        const priority = defaultPriorityData.find(
          (d) => d._id == info.row.original[info.column.id]
        );
        return (
          <>
            <SelectField
              isClearable={false}
              isMulti={false}
              placeholder="Select Priority"
              defaultOptions={defaultPriorityData.map((priority) => ({
                label: priority.Priority,
                value: priority._id,
              }))}
              value={{ label: priority.Priority, value: priority._id }}
              className="w-40"
              onChange={(e) => {
                const newValue = e.value;
                // Update the row data
                setRequestPriority(info.row.original._id, newValue).then(
                  async (res) => {
                    toastr.show("Priority sent successfully", "success");
                    const response = await getAllPendingRequests({
                      page: currentPage || 1,
                      pageSize: pageSize || 10,
                    });
                    if (response.data) {
                      setRequestData(response.data.requests || response.data);
                    }
                  }
                );
              }}
            />
          </>
        );
      },
      footer: (info) => info.column.id,
    }),
  ];

  const filterRequest = async () => {
    const payload = {
      page: currentPage || 1,
      pageSize: pageSize || 10,
    };
    if (filter.status) payload.status = filter.status;
    if (filter.subject) payload.subject = filter.subject;
    if (filter.priority) payload.priority = filter.priority;
    if (filter.startDate) payload.startDate = filter.startDate;
    if (filter.endDate) payload.endDate = filter.endDate;
    try {
      setDisablePaginationButton(true);
      const response = await getAllPendingRequests(payload);
      if (response.data) {
        setRequestData(response.data.requests || response.data);
        setTotalPages(response.data.totalPages || 1);
        setTotalRequestCount(response.data.totalCount || 0);
      }
    } catch (error) {
      toastr.show(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
    setDisablePaginationButton(false);
  };
  useEffect(() => {
    filterRequest();
  }, [filter]);

  const getRequestData = async () => {
    try {
      setDisablePaginationButton(true);
      const response = await getAllPendingRequests({
        page: currentPage || 1,
        pageSize: pageSize || 10,
      });
      if (response.data) {
        setRequestData(response.data.requests || response.data);
        setTotalPages(response.data.totalPages || 1);
        setTotalRequestCount(response.data.totalCount || 0);
      }
    } catch (error) {
      toastr.show(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
    setDisablePaginationButton(false);
  };

  useEffect(() => {
    getRequestData();
  }, [pageSize, currentPage]);

  const table = useReactTable({
    data: requestData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handlePageChange = (direction) => {
    switch (direction) {
      case "prev":
        if (currentPage > 0) {
          setCurrentPage((prev) => prev - 1);
        }
        break;

      case "next":
        if (currentPage < totalPages) {
          setCurrentPage((prev) => prev + 1);
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <table className="w-full member-table overflow-auto">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="px-2 py-4"
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " 🔼",
                            desc: " 🔽",
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="last:!border-b-0">
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className="border-b border-stone-200 text-sm">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className="text-center px-1 py-2">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex items-center mt-3">
        <div className="flex font-bold">
          Total Requests : <span className="ml-2">{totalRequestCount}</span>
        </div>

        <div className="ml-auto flex gap-2">
          <button
            className="border rounded w-8 h-8 grid place-items-center"
            onClick={() => handlePageChange("prev")}
            disabled={currentPage === 1 || disablePaginationButton}
          >
            <Icon icon="lucide:chevron-left" size={1} className="text-xl" />
          </button>
          <button
            className="border rounded w-8 h-8 grid place-items-center"
            onClick={() => handlePageChange("next")}
            disabled={currentPage === totalPages || disablePaginationButton}
          >
            <Icon icon="lucide:chevron-right" size={1} className="text-xl" />
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {currentPage} of {totalPages}
            </strong>
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="outline-none p-1 border rounded "
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>

      {openHistory && (
        <ShowHistoryOfRequest
          openHistory={openHistory}
          setOpenHistory={setOpenHistory}
          data={requestHistory}
          requestId={requestId}
          sendReply={sendReply}
          statusData={statusData}
          subjectData={subjectData}
        />
      )}
    </>
  );
}

export default RequestTable;
