import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import CreateSabhaForm from "./CreateSabhaForm";

const CreateSabha = () => {
  return (
    <div className="h-[calc(100vh_-_81px)] 2xl:p-[20px] overflow-auto">
      <div className="mx-auto pb-10">
        <div className="w-max text-center m-2">
          <div className="text-[25px] text-[#212121]">Add Sabha</div>
        </div>
        <CreateSabhaForm />
      </div>
    </div>
  );
};

export default CreateSabha;
