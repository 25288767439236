import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, DialogPanel, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose } from "@mdi/js";
import { toastr } from "../../services/common.service";
import { createSevak, updateSevak } from "./actionCreator";
import { useSelector } from "react-redux";
import SelectField from "../Common/Input/SelectField";
import { SevakStatus } from "../../constants/common.constants";

const AddSevakModel = ({
    open,
    setOpen,
    sevakDetail,
    allUsers,
    allSevak,
    sevaId,
    parentId,
    fetchSevaDetail
}) => {
    const { FullNameList } = useSelector((state) => state.UserReducer)
    const [enabled, setEnabled] = useState(false);
    const [onDuty, setOnDuty] = useState(false)
    const [loggedInUser, setLoggedInUser] = useState({})
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        let fetchLoggedInUser = localStorage.getItem("loggedInUser");
        setLoggedInUser(JSON.parse(fetchLoggedInUser));
    }, [])


    useEffect(() => {
        if (sevakDetail && sevakDetail?._id) {
            setEnabled(sevakDetail?.isHead);
            setOnDuty(sevakDetail?.onDuty);
            let name = `${sevakDetail?.userId.firstName} ${sevakDetail?.userId?.middleName} ${sevakDetail?.userId?.lastName} | ${sevakDetail?.userId?.mobile}`;
            let fieldName = {
                label: name,
                value: name
            };
            setUserInfo(
                {
                    ...userInfo,
                    SMVNo: sevakDetail?.userId?.SMVNo.toString(),
                    fullName: fieldName,
                    sevakStatus: SevakStatus[sevakDetail?.sevakStatus]
                }
            );

        }
    }, [sevakDetail])

    const resetForm = () => {
        setEnabled(false);
        setOnDuty(false);
        setUserInfo({});
        setOpen(false);
    };




    const addNewSevaInfo = async () => {
        try {
            if (userInfo.fullName === null || userInfo.fullName === undefined || userInfo.fullName === "") {
                return toastr.show("Please Enter Sevak", "error");
            }

            let selectedUser = allUsers.find((item) => item.SMVNo + "" === parseInt(userInfo?.SMVNo) + "");

            let formData = {
                userId: selectedUser?._id,
                sevaId,
                parentDeptId: parentId,
                entryUserId: loggedInUser._id,
                isHead: enabled,
                onDuty,
            }
            await createSevak(formData);
            await fetchSevaDetail(parentId, sevaId);
            resetForm()

        } catch (err) {
            console.error("Error uploading files:", err);
        }
    };

    const updateSevaInfo = async () => {
        try {
            if (userInfo?.fullName === "" || userInfo?.fullName === null || userInfo?.fullName === undefined) {
                return toastr.show("Please Enter Title", "error");
            }

            let selectedUser = allUsers.find((item) => item.SMVNo + "" === parseInt(userInfo?.SMVNo) + "");

            const formData = {
                _id: sevakDetail?._id,
                userId: selectedUser?._id,
                sevaId,
                parentDeptId: parentId,
                entryUserId: loggedInUser._id,
                isHead: enabled,
                onDuty,
            }

            await updateSevak(formData);
            await fetchSevaDetail(parentId, sevaId);

            resetForm()
        } catch (err) {
            console.error("Error uploading files:", err);
        }

    };



    const handleSMVInputChange = (value) => {
        if (value) {
            setUserInfo({ ...userInfo, SMVNo: value })
            let findUser = allUsers.find((item) => item.SMVNo + "" === parseInt(value) + "");
            if (findUser) {
                let name = `${findUser.firstName} ${findUser.middleName} ${findUser.lastName} | ${findUser.mobile}`;
                let fieldName = {
                    label: name,
                    value: name
                };
                setUserInfo({ ...userInfo, fullName: fieldName })
            } else {
                setUserInfo({ ...userInfo, fullName: {} })

            }
        } else {
            setUserInfo({ ...userInfo, fullName: {} })

        }
    };

    const handleFullNameChange = (selectedOption) => {
        if (selectedOption) {
            // setFullName(selectedOption);
            let findUser = allUsers.find((item) => `${item.firstName} ${item.middleName} ${item.lastName} | ${item.mobile}` === selectedOption.value);
            if (findUser) {
                setUserInfo({ ...userInfo, fullName: selectedOption, SMVNo: findUser.SMVNo.toString() })
            } else {
                setUserInfo({ ...userInfo, SMVNo: "" })
            }
        } else {
            setUserInfo({ ...userInfo, fullName: {}, SMVNo: "" })
        }
    };

    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={() => { }}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </TransitionChild>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogPanel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="flex justify-between pb-4 border-b border-gray400">
                                        <div className="text-lg font-semibold text-orange-600">
                                            Add Sevak
                                        </div>
                                        <Icon
                                            path={mdiClose}
                                            size={1}
                                            className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                                            onClick={resetForm}
                                        />
                                    </div>
                                    <div className="p-6">
                                        <div className="flex flex-col">
                                            <div className="flex flex-col">
                                                <div className="flex-grow flex justify-center">
                                                    <div className="max-w-lg w-full">
                                                        <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                                                            <div className="max-w-[150px] mb-5">
                                                                <Input label="SMVNo" value={userInfo?.SMVNo} disabled={sevakDetail?._id ? true : false} onChange={(e) => handleSMVInputChange(e.target.value)} />
                                                            </div>
                                                            <div className="mb-5 relative">
                                                                <SelectField
                                                                    className="!h-9 placeholder:text-black900"
                                                                    required={false}
                                                                    placeholder="Select User"
                                                                    isMulti={false}
                                                                    defaultOptions={FullNameList}
                                                                    value={userInfo?.fullName}
                                                                    onChange={handleFullNameChange}
                                                                    isDisabled={sevakDetail?._id ? true : false}
                                                                />
                                                            </div>
                                                            <div className="mb-5 relative">
                                                                <SelectField
                                                                    className="!h-9 placeholder:text-black900"
                                                                    required={false}
                                                                    placeholder="Select Sevak Status"
                                                                    isMulti={false}
                                                                    defaultOptions={SevakStatus}
                                                                    value={userInfo?.sevakStatus}
                                                                    onChange={(value) => setUserInfo({ ...userInfo, sevakStatus: value })}
                                                                />
                                                            </div>
                                                            <div class="flex item-center mb-5">
                                                                <div className="flex items-center mr-5">
                                                                    <div className="mr-3  text-lg">
                                                                        Is Head
                                                                    </div>
                                                                    <Switch
                                                                        checked={enabled}
                                                                        onChange={setEnabled}
                                                                        className={`${enabled ? "bg-blue-600" : "bg-gray-200"
                                                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                    >
                                                                        <span
                                                                            className={`${enabled
                                                                                ? "translate-x-6"
                                                                                : "translate-x-1"
                                                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                        />
                                                                    </Switch>
                                                                </div>
                                                                <div className="flex items-center">
                                                                    <div className="mr-3  text-lg">
                                                                        On Duty
                                                                    </div>
                                                                    <Switch
                                                                        checked={onDuty}
                                                                        onChange={setOnDuty}
                                                                        className={`${onDuty ? "bg-blue-600" : "bg-gray-200"
                                                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                    >
                                                                        <span
                                                                            className={`${onDuty
                                                                                ? "translate-x-6"
                                                                                : "translate-x-1"
                                                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                        />
                                                                    </Switch>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-right flex justify-end mt-6">
                                            <div className="mr-5">
                                                <Button
                                                    color="red"
                                                    variant="outlined"
                                                    className="capitalize text-base"
                                                    onClick={resetForm}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    className="capitalize text-base bg-orange-600"
                                                    onClick={() =>
                                                        sevakDetail?._id
                                                            ? updateSevaInfo()
                                                            : addNewSevaInfo()
                                                    }
                                                >
                                                    {sevakDetail?._id ? "Update" : "Create"}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default AddSevakModel;
