import React from "react";
import { useRoutes } from "react-router-dom";
import {
  Books,
  DailyDarshan,
  SixDham,
  EventBanner,
  EventPhoto,
  MantraJap,
  SabhaPosts,
  Wallpaper,
} from "../../../Components";
export default function BannerRouter() {
  const BannerRoute = [
    {
      path: "dailydarshan/*",
      element: (
        <>
          <DailyDarshan />
        </>
      ),
    },
    {
      path: "sixdham/*",
      element: (
        <>
          <SixDham />
        </>
      ),
    },
    {
      path: "sabhaposts/*",
      element: (
        <>
          <SabhaPosts />
        </>
      ),
    },
    {
      path: "eventBanners/*",
      element: (
        <>
          <EventBanner />
        </>
      ),
    },
    {
      path: "eventphoto/*",
      element: (
        <>
          <EventPhoto />
        </>
      ),
    },
    {
      path: "mantraJap/*",
      element: (
        <>
          <MantraJap />
        </>
      ),
    },
    {
      path: "wallpaper/*",
      element: (
        <>
          <Wallpaper />
        </>
      ),
    },
    {
      path: "books/*",
      element: (
        <>
          <Books />
        </>
      ),
    },
  ];
  const BannerRouter = useRoutes(BannerRoute);
  return BannerRouter;
}
