export const RouteLink = [
  {
    id: 1,
    title: "photo & banner",
    subMenu: [
      {
        title: "daily darshan",
        link: "/photo&banner/dailydarshan",
        permission: "MANAGE_DARSHAN",
      },
      {
        title: "six dham darshan",
        link: "/photo&banner/sixdham",
        permission: "MANAGE_DARSHAN",
      },
      {
        title: "Upcomming Events",
        link: "/photo&banner/sabhaposts",
        permission: "MANAGE_DARSHAN",
      },
      {
        title: "event banner",
        link: "/photo&banner/eventBanners",
        permission: "MANAGE_DARSHAN",
      },
      {
        title: "event photo",
        link: "/photo&banner/eventphoto",
        permission: "MANAGE_DARSHAN",
      },
      {
        title: "mantra Jap",
        link: "/photo&banner/mantraJap",
        permission: "MANAGE_DARSHAN",
      },
      {
        title: "wallpaper",
        link: "/photo&banner/wallpaper",
        permission: "MANAGE_DARSHAN",
      },
      {
        title: "Books",
        link: "/photo&banner/Books",
        permission: "MANAGE_DARSHAN",
      },
    ],
  },
  {
    id: 2,
    title: "attendance",
    subMenu: [
      {
        title: "members",
        link: "/attendance/members",
        permission: "MANAGE_USERS",
      },
      {
        title: "sabha",
        link: "/attendance/createSabha",
        permission: "MANAGE_SABHA",
      },
      {
        title: "Department",
        link: "/attendance/saints",
        permission: "ADMIN_ONLY",
      },
      {
        title: "Permissions",
        link: "/attendance/permissions",
        permission: "ADMIN_ONLY",
      },
      {
        title: "Member Of.",
        link: "/attendance/member-of",
        permission: "ADMIN_ONLY",
      },
    ],
  },
  {
    id: 2.5,
    title: "address",
    subMenu: [
      {
        title: "Society List",
        link: "/address/society-list",
        permission: "MANAGE_SOCIETY",
      },
      {
        title: "Attach Society Index",
        link: "/address/attach-society-index",
        permission: "MANAGE_SOCIETY",
      },
    ],
  },
  {
    id: 3,
    title: "Video",
    subMenu: [
      {
        title: "Live Darshan",
        link: "/video/live",
        permission: "MANAGE_LIVE",
      },
      {
        title: "Live Katha",
        link: "/video/katha-live",
        permission: "MANAGE_LIVE",
      },
      {
        title: "Playlist",
        link: "/video/playlist",
        permission: "MANAGE_LIVE",
      },
      {
        title: "Customize Playlist",
        link: "/video/customizePlaylist",
        permission: "MANAGE_LIVE",
      },
    ],
  },
  {
    id: 4,
    title: "New Entry",
    subMenu: [
      {
        title: "Entry",
        link: "/newentry/memberList",
        permission: "MANAGE_NEW_ENTRY",
      },
      {
        title: "Photo",
        link: "/newentry/photo",
        permission: "MANAGE_NEW_ENTRY",
      },
    ],
  },
  {
    id: 4,
    title: "Mantra Lekhan",
    subMenu: [
      {
        title: "Audit",
        link: "/mantraLekhan/audit",
        permission: "MANTRA_LEKHAN",
      },
      {
        title: "Reports",
        link: "/mantraLekhan/reports",
        permission: "MANTRA_LEKHAN",
      },
      {
        title: "Audio Album",
        link: "/audio/album",
        permission: "MANAGE_LIVE",
      },
    ],
  },
  {
    id: 6,
    title: "Reports",
    subMenu: [
      {
        title: "Attendance",
        link: "/reports/attendance",
        permission: "ADMIN_ONLY",
      },
    ],
  },
  {
    id: 7,
    title: "Form",
    subMenu: [
      {
        title: "List",
        link: "/form/list",
        permission: "MANAGE_FORM",
      },
      {
        title: "other sabha",
        link: "/form/otherSabha",
        permission: "MANAGE_FORM",
      },
    ],
  },
  {
    id: 8,
    title: "Book",
    subMenu: [
      {
        title: "Books List",
        link: "/book/list",
        permission: "MANAGE_DARSHAN",
      },
    ],
  },
  {
    id: 9,
    title: "Support",
    subMenu: [
      {
        title: "Request",
        link: "/support/request",
        permission: "ADMIN_ONLY",
      },
    ],
  },
  {
    id: 10,
    title: "Seva",
    subMenu: [
      {
        title: "Seva List",
        link: "/seva/list",
      },
    ],
  },
  {
    id: 11,
    title: "Padharamani Assignment",
    subMenu: [
      {
        title: "Manage",
        link: "/padharamani/manage",
      }]
  },
  {
    id: 12,
    title: "Katha",
    subMenu: [
      {
        title: "Katha Pdf",
        link: "/katha-pdf/katha",
        permission: "MANAGE_LIVE",
      },
    ],
  },
];
