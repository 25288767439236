import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-tailwind/react";
import { SketchPicker } from "react-color";
import DrawerComponent from "../Common/Drawer/Drawer";
import { languageType } from "../../constants/common.constants";
import { api } from "../../services/api.service";

const LineItemSelector = () => {
  const [katha, setKatha] = useState("");
  const [lineItems, setLineItems] = useState([]);
  const [selectedLineItem, setSelectedLineItem] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [textColor, setTextColor] = useState("#000000");
  const [backgroundColor, setBackgroundColor] = useState("#f7f7f7");
  const [showTextColorPicker, setShowTextColorPicker] = useState(false);
  const [showBackgroundColorPicker, setShowBackgroundColorPicker] =
    useState(false);
  const listRef = useRef(null);
  const itemRefs = useRef({});
  const [selectLanguage, setSelectLanguage] = useState(languageType[1]);
  const [open, setOpen] = useState(false);
  const [kathaId, setKathaId] = useState(null);
  const [title, setTitle] = useState("");

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const saveKatha = async () => {
    const response = await api.post("/admin/katha/create", {
      content: katha,
      title: title,
    });
    setTitle(response.data.title);
    const lines =
      response?.data?.content
        .split("\n")
        .filter((line) => line.trim() !== "") || "";
    setLineItems(lines);
    return response;
  };

  const getKatha = async (id) => {
    const response = await api.get("/admin/katha");
    setKatha(response?.data?.content);
    setTitle(response?.data?.title);
    const lines =
      response?.data?.content
        .split("\n")
        .filter((line) => line.trim() !== "") || "";
    setLineItems(lines);
    // setKathaId(response.data._id);
    return response;
  };

  useEffect(() => {
    getKatha();
  }, [kathaId]);

  // Handle text input and convert to line items
  const handleTextChange = (e) => {
    const inputText = e.target.value;
    setKatha(inputText);
    const lines = inputText.split("\n").filter((line) => line.trim() !== "");
    setLineItems(lines);
  };

  // Handle selecting a line item
  const handleSelectLine = (line, index) => {
    setSelectedLineItem(line);
    setSelectedIndex(index);
  };

  // Scroll selected item into view
  useEffect(() => {
    if (selectedIndex >= 0 && itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedIndex]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (lineItems.length === 0) return;

      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          setSelectedIndex((prev) => {
            const newIndex = prev <= 0 ? lineItems.length - 1 : prev - 1;
            setSelectedLineItem(lineItems[newIndex]);
            return newIndex;
          });
          break;
        case "ArrowDown":
          e.preventDefault();
          setSelectedIndex((prev) => {
            const newIndex = prev >= lineItems.length - 1 ? 0 : prev + 1;
            setSelectedLineItem(lineItems[newIndex]);
            return newIndex;
          });
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [lineItems]);

  return (
    <>
      <div className="h-[calc(100vh_-_58px)]">
        <div className="flex m-4 items-center justify-between">
          <div className="px-4">
            <div className="flex space-x-0">
              <Button
                className="mr-4"
                onClick={() => setShowTextColorPicker((prev) => !prev)}
              >
                Text
              </Button>
              <Button
                onClick={() => setShowBackgroundColorPicker((prev) => !prev)}
              >
                bg
              </Button>
            </div>

            {showTextColorPicker && (
              <div className="absolute z-20 bottom-400">
                <SketchPicker
                  color={textColor}
                  onChangeComplete={(color) => setTextColor(color.hex)}
                />
              </div>
            )}

            {showBackgroundColorPicker && (
              <div className="absolute z-20 bottom-400 left-72">
                <SketchPicker
                  color={backgroundColor}
                  onChangeComplete={(color) => setBackgroundColor(color.hex)}
                />
              </div>
            )}
          </div>

          <h3 className="text-center flex-grow">Dhanurmas Katha pdf</h3>

          <Button onClick={openDrawer}>Add Text</Button>
        </div>

        <div className="h-[calc(100%_-_200px)] px-10">
          <ul
            ref={listRef}
            className="h-full"
            style={{
              listStyle: "none",
              padding: 0,
              overflowY: "auto",
              margin: "0 auto",
              fontFamily: selectLanguage.label,
            }}
          >
            {lineItems?.length > 0 &&
              lineItems?.map((line, index) => (
                <li
                  key={index}
                  ref={(el) => (itemRefs.current[index] = el)}
                  style={{
                    padding: "5px",
                    border: "1px solid #ddd",
                    marginBottom: "5px",
                    fontSize: "24px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedIndex === index ? "#f0f8ff" : "#fff",
                    outline:
                      selectedIndex === index ? "2px solid #007bff" : "none",
                    textAlign: "center",
                    fontFamily: selectLanguage.label || "sans-serif",
                  }}
                  onClick={() => handleSelectLine(line, index)}
                >
                  {line}
                </li>
              ))}
          </ul>
        </div>

        <div
          className="fixed bottom-0 left-0 w-full text-center p-2 border-t border-[#ddd]"
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
            padding: "5px",
            borderTop: "5px solid #ffd191",
            fontFamily: selectLanguage.label || "sans-serif",
          }}
        >
          <p className="text-[5rem] pb-3">
            {selectedLineItem || "Hg `E Mkteblthtg="}
          </p>
        </div>
      </div>

      <DrawerComponent
        open={open}
        onClose={closeDrawer}
        onTextChange={handleTextChange}
        textValue={katha}
        selectLanguage={selectLanguage}
        setLanguageType={setSelectLanguage}
        saveKatha={saveKatha}
        setTitle={setTitle}
        title={title}
      />
    </>
  );
};

export default LineItemSelector;
