import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { downloadSocietyIndexPDF } from "./actionCreator";

export default function PdfComponent(props) {
  let componentRef = useRef();
  let printBtnRef = useRef();
  const { lastAPIParams } = props;
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (reportData.length > 0) {
      setTimeout(() => {
        printBtnRef.current.click();
      }, 100);
    }
  }, [reportData]);

  const myFunction = async () => {
    const queryParams = {
      ...lastAPIParams,
    };

    if (queryParams?.societyIndexes) {
      queryParams.selectedIndexes = queryParams.societyIndexes.map((d) => d.value).join(",");
    }

    const response = await downloadSocietyIndexPDF(queryParams);
    if (response.data) {
      setReportData(response.data);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div>
        <button
          style={{ display: "none" }}
          ref={printBtnRef}
          onClick={handlePrint}
        >
          Print
        </button>
        <div>
          <button
            className="bg-[#007AFF] text-white px-3 py-2 rounded-[5px] ml-2"
            onClick={() => myFunction()}
          >
            Download Full PDF
          </button>
        </div>

        {/* component to be printed */}
        <div style={{ display: "none" }} className="w-fit">
          <div ref={componentRef}>
            {reportData.map((data) => (
              <div key={data._id} className="">
                <div className="text-lg mb-2 m-4 justify-center">
                  {data.societyIndex} - {data.society || "N/A"},{" "}
                  {data.streetName || "N/A"}, {data.city || "N/A"} {"...."} કુલ
                  પધરામણી :-{" "}
                  {data.users.filter((user) => user.flag_3 === true)?.length ||
                    0}
                  {/* {data.users.filter((user) => user.flag_4 === true)?.length ||
                    0} */}
                </div>

                <table className="table-auto w-[800px] border-collapse">
                  <thead>
                    <tr>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1 border-r border-l">
                        SMV No
                      </th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1 border-r">
                        Home/Flat No
                      </th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1 border-r">
                        Name
                      </th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1 border-r">
                        Mobile
                      </th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1 border-r">
                        Village
                      </th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1 border-r">
                        Sabha
                      </th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1 border-r">
                        Padhramni
                      </th>
                      {/* <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1 border-r-2">
                        Pending
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.users.map((member) => (
                      <tr key={member?._id} className="text-center p-2">
                        <td className="p-2 border-b border-blue-gray-50 border-r border-l">
                          {member.SMVNo}
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          {member?.address?.blockNo}
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          {member.firstName} {member.middleName}{" "}
                          {member.lastName}
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          {member.mobile}
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          {member.village}
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          {member.attendeeOf?.[0]?.sabhaName[0]}
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          {member.flag_3 && ""}
                        </td>
                        {/* <td className="p-2 border-b border-blue-gray-50 border-r-2">
                          {member.flag_4 && "Pending"}
                        </td> */}
                      </tr>
                    ))}
                    {Array.from({ length: 5 }).map((_, index) => (
                      <tr key={`blank-${index}`} className="text-center p-2">
                        <td className="p-2 border-b border-blue-gray-50 border-r border-l">
                          &nbsp;
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          &nbsp;
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          &nbsp;
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          &nbsp;
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          &nbsp;
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          &nbsp;
                        </td>
                        <td className="p-2 border-b border-blue-gray-50 border-r">
                          &nbsp;
                        </td>
                        {/* <td className="p-2 border-b border-blue-gray-50 border-r-2">
                          &nbsp;
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
