import React, { useRef, useState } from "react";
import Select from "react-select";

const customStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: "1px solid #b0bec5",
    borderRadius: "8px",
    minHeight: "30px",
    backgroundColor: "#fff",
    "&:focus": {
      outline: "none",
      borderColor: "#b0bec5",
    },
    "&:hover": {
      border: "1px solid #b0bec5",
    },
    "&:placeholder": {
      color: "red",
    },
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: "#C6C6C6",
    fontSize: "14px",
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
  }),
};

export default function SelectField(props) {
  const {
    placeholder,
    label,
    id,
    required,
    defaultOptions,
    onChange,
    value,
    defaultValue,
    // menuPosition = "absolute",
    menuWidth = "100%",
    isDisabled = false,
    className,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const menuContainerRef = useRef(null);

  return (
    <div className={`${className ? className : "w-full"}`}>
      {label && (
        <label
          htmlFor={id}
          className="text-sm text-gray300 font-semibold mb-2 inline-block"
        >
          {label}
          {required ? <span className="text-red400">*</span> : <></>}
        </label>
      )}
      <Select
        isDisabled={isDisabled}
        isLoading={isLoading}
        placeholder={placeholder}
        onChange={onChange}
        options={defaultOptions}
        value={value}
        classNames={props?.className}
        styles={customStyles}
        // menuPosition={menuPosition}
        defaultValue={defaultValue}
        menuPortalTarget={document.body}

      />
    </div>
  );
}
