import React, { useState, useEffect, useMemo } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useParams } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { Button } from "@material-tailwind/react";
import { useReactNavigator } from "../../utils/reactNavigator";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { motion } from "framer-motion";
import { Switch } from "@headlessui/react";
import clsx from "clsx";
import { changeSevakDuty, deleteSevak } from "./actionCreator";
import { toastr } from "../../services/common.service";
import AlertModal from "../Common/CustomConfirmationModal/CustomConfirmationModal";

const DraggableRow = ({ row, reorderSeva }) => {
    const [, dropRef] = useDrop({
        accept: "row",
        drop: (draggedRow) => reorderSeva(draggedRow.index, row.index),
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: "row",
    });

    return (
        <motion.tr
            ref={previewRef}
            initial={{ opacity: 1 }}
            animate={{ opacity: isDragging ? 0.5 : 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
        >
            <td ref={dropRef} className="text-start w-[50px]">
                <button ref={dragRef} className="cursor-grab">
                    <Icon icon="grommet-icons:drag" className="text-xl" />
                </button>
            </td>
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </motion.tr>
    );
};
const SevakTable = ({ data, setData, sevaDetail, openSevakFormLink, fetchSevaDetail, sevaId, parentId }) => {
    const [showConfirmationModel, setShowConfirmationModel] = useState(false);
    const [currentIndexId, setCurrentIndexId] = useState("");


    const deleteSevakConfirmation = async (id) => {
        setShowConfirmationModel(!showConfirmationModel);
        setCurrentIndexId(id);
    };

    const handleDeleteSevak = async (status) => {
        if (status) {
            try {
                if (currentIndexId) {
                    await deleteSevak({ _id: currentIndexId });
                    await fetchSevaDetail(parentId, sevaId);
                    toastr.show("Sevak Deleted Successfully", "success");
                }
            } catch (err) {
                toastr.show("Something went wrong", "error");
            }
        }
        setShowConfirmationModel(false);
    }

    const updateSevakDuty = async (userId, sevaId, onDuty) => {
        try {
            let payload = {
                userId,
                sevaId,
                onDuty,
            }
            const response = await changeSevakDuty(payload);
            if (response) {
                toastr.show("Sevak duty changed successfully!", "success");
                await fetchSevaDetail(parentId, sevaId);
            }
        } catch (err) {
            console.error("Error changing seva status:", err);
        }
    };

    const reorderSeva = (draggedRowIndex, targetRowIndex) => {
        data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
        setData([...data]);
        const indexIds = data.map((d) => d._id);
    };

    const columns = useMemo(() => [
        {
            accessorKey: "SMVNo",
            header: () => <span>SMVNo.</span>,
            cell: (info) => info.row.original?.userId?.SMVNo,
            id: "SMVNo",
        },
        {
            accessorKey: "fullName",
            header: () => <span>Name.</span>,
            cell: (info) => (
                <div className="">
                    {info.row.original?.userId?.firstName} {info.row.original?.userId?.middleName}{" "}
                    {info.row.original?.userId?.lastName}
                </div>
            ),
            id: "fullName",
            // size: 450,
        },
        {
            accessorKey: "mobile",
            header: () => <span>Phone No.</span>,
            cell: (info) => info.row.original?.userId?.mobile,
            id: "mobile",
        },
        {
            accessorKey: "onDuty",
            header: () => <span>Duty</span>,
            cell: (info) => {
                return (
                    <Switch
                        checked={info.getValue()}
                        className={`${info.getValue() ? "bg-blue-600" : "bg-gray-200"
                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                        onClick={() =>
                            updateSevakDuty(info.row.original?.userId._id, sevaDetail?._id, !info.getValue())
                        }
                    >
                        <span
                            className={`${info.getValue() ? "translate-x-6" : "translate-x-1"
                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                    </Switch>
                )
            },
            id: "onDuty"
        },
        {
            header: () => <span className="text-center"></span>,
            id: "actions",
            cell: (props) => {
                return (
                    <span className="flex items-center justify-end">
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
                            <button onClick={() => openSevakFormLink(props.row.original?._id)}>
                                <Icon
                                    icon="ic:baseline-edit"
                                    className="text-2xl transition-all duration-150 text-black900"

                                />
                            </button>
                        </span>
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
                            <button onClick={() => deleteSevakConfirmation(props.row.original?._id)}>
                                <Icon
                                    icon="material-symbols:delete"
                                    className="transition-all duration-150 text-2xl text-black900"
                                />
                            </button>
                        </span>

                    </span>
                );
            },
        },
    ]);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (row) => row.id,
    });

    return (
        <>
            <div>
                <div className="flex items-center mb-3">
                    <div className="text-xl font-medium">Sevak</div>
                </div>
                <div className="overflow-auto">
                    <DndProvider backend={HTML5Backend}>
                        <table className="w-full">
                            <thead>
                                {table.getHeaderGroups().map((headerGroup, index) => {
                                    return (
                                        <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]" key={index}>
                                            <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                                            {headerGroup.headers.map((header) => {
                                                return (
                                                    <th
                                                        key={header.id}
                                                        colSpan={header.colSpan}
                                                        className={clsx(header.id === "actions" ? "text-right" : "text-left", "font-semibold p-2")}
                                                    >
                                                        {header.isPlaceholder ? null : (
                                                            <div
                                                                {...{
                                                                    className: clsx(header.column.getCanSort()
                                                                        ? "cursor-pointer select-none"
                                                                        : "last:text-center last:w-[120]"),
                                                                }}
                                                            >
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                            </div>
                                                        )}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </thead>
                            <tbody className="last:!border-b-0">
                                {table.getRowModel().rows.map((row) => (
                                    <DraggableRow
                                        key={row.id}
                                        row={row}
                                        reorderSeva={reorderSeva}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </DndProvider>
                </div>
            </div>
            <AlertModal
                open={showConfirmationModel}
                handleCloseModal={handleDeleteSevak}
                messageText="Are you sure you want to delete this Sevak?"
                submitBtnText="Confirm"
                buttonType="primary"
                icon="icon-park:go-end"
            />
        </>
    );
};

export default SevakTable;