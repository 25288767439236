import React from "react";
import TextField from "../../Common/Input/TextField";
import SelectField from "../../Common/Input/SelectField";
import CreatableMultiselectMain from "../../Common/Input/CreatableMultiselectMain";
import PhoneInputField from "../../Common/PhoneInput";
import { SABHA_TYPES, genderType } from "../../../constants/common.constants";
import { Input } from "@material-tailwind/react";
import countryCodeData from "../../../constants/CountryCodes.json";

const BasicInfo = ({
  memberDetails,
  setMemberDetails,
  streetNameList,
  societyList,
  cityList,
  stateList,
  countryList,
  isNewUser = false,
}) => {
  const defaultSelectedCountry = [
    {
      label: "+91",
      value: "91",
      code: "IN",
    },
  ];

  const defaultCity = [
    {
      label: "સુરત",
      value: "સુરત",
    },
  ];
  const defaultCountry = [
    {
      label: "ભારત",
      value: "ભારત",
    },
  ];
  const defaultState = [
    {
      label: "ગુજરાત",
      value: "ગુજરાત",
    },
  ];
  return (
    <>
      {isNewUser && (
        <div className="grid grid-cols-3 w-full gap-x-10 gap-5 mb-5">
          <div className="w-full">
            <SelectField
              id="08"
              placeholder="Select Sabha"
              value={memberDetails?.attendeeOf}
              defaultOptions={SABHA_TYPES.map((sType) => ({
                label: sType.name,
                value: sType._id,
              }))}
              onChange={(selectedSabha) =>
                setMemberDetails("attendeeOf", selectedSabha)
              }
            />
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 gap-x-10 gap-y-5">
        <div className="">
          <TextField
            id="01"
            className="placeholder:gray100"
            required={true}
            label="First Name"
            placeholder="First Name"
            value={memberDetails.firstName}
            onChange={(e) => setMemberDetails("firstName", e?.target?.value)}
          />
        </div>
        <div className="">
          <TextField
            id="02"
            className="placeholder:gray100"
            required={true}
            label="Middle Name"
            placeholder="Middle Name"
            value={memberDetails.middleName}
            onChange={(e) => setMemberDetails("middleName", e?.target?.value)}
          />
        </div>
        <div className="">
          <TextField
            id="03"
            className="placeholder:gray100"
            required={true}
            label="Last Name"
            placeholder="Last Name"
            value={memberDetails.lastName}
            onChange={(e) => setMemberDetails("lastName", e?.target?.value)}
          />
        </div>
        <div className="flex">
          <div className="flex-none">
            <SelectField
              className="placeholder:gray100"
              label="Code"
              id="041"
              isMulti={false}
              placeholder="Select Country"
              options={countryCodeData}
              isSearchable={true}
              value={{
                label: `+${memberDetails?.countryCode || "91"}`,
                value: memberDetails?.countryCode,
              }}
              defaultValue={defaultSelectedCountry}
              defaultOptions={countryCodeData.map((countyObj) => ({
                label: `${countyObj.name} +${countyObj.dial_code}`,
                value: countyObj.dial_code,
              }))}
              onChange={(e) => {
                setMemberDetails("countryCode", String(e?.value || "91"));
              }}
            />
          </div>
          <div className="flex-none pl-1">
            <div className="relative max-w-[170px]">
              <span className="text-sm text-gray300 font-semibold mb-2 inline-block">
                Mobile Number 1
              </span>
              <input
                placeholder="Mobile Number"
                className="peer h-10 max-h-[38px] w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 focus:outline-0"
                required={true}
                maxLength={10}
                min={10}
                value={memberDetails.mobile}
                onChange={(value) =>
                  setMemberDetails("mobile", value?.target?.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="flex-none">
            <SelectField
              className="placeholder:gray100"
              label="Code"
              id="041"
              isMulti={false}
              placeholder="Select Country"
              options={countryCodeData}
              isSearchable={true}
              value={{
                label: `+${memberDetails?.countryCode2 || "91"}`,
                value: memberDetails?.countryCode2,
              }}
              defaultValue={defaultSelectedCountry}
              defaultOptions={countryCodeData.map((countyObj) => ({
                label: `${countyObj.name} +${countyObj.dial_code}`,
                value: countyObj.dial_code,
              }))}
              menuWidth="230px"
              onChange={(e) => {
                setMemberDetails("countryCode2", String(e?.value || "91"));
              }}
            />
          </div>
          <div className="flex-none pl-1">
            <div className="relative max-w-[170px]">
              <span className="text-sm text-gray300 font-semibold mb-2 inline-block">
                Mobile Number 2
              </span>
              <input
                placeholder="Mobile Number 2"
                className="peer h-10 max-h-[38px] w-full rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 focus:outline-0"
                value={memberDetails.mobile2}
                maxLength={10}
                min={10}
                onChange={(value) =>
                  setMemberDetails("mobile2", value?.target?.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="">
          <TextField
            type="email"
            className="placeholder:gray100"
            label="Email"
            placeholder="Email"
            value={memberDetails.email}
            onChange={(e) => setMemberDetails("email", e?.target?.value)}
          />
        </div>
        <div className="">
          <TextField
            className="placeholder:gray100"
            label="Home /Flat no."
            placeholder="Home /Flat no."
            id="06"
            value={memberDetails?.address?.blockNo || memberDetails.blockNo}
            onChange={(e) => setMemberDetails("blockNo", e?.target?.value)}
          />
        </div>
        <div className="relative">
          <CreatableMultiselectMain
            className="placeholder:gray100"
            label="Society"
            id="07"
            isMulti={false}
            placeholder="Select Society"
            value={
              memberDetails?.society || memberDetails?.address?.society
                ? [
                    {
                      label:
                        memberDetails?.society ||
                        memberDetails?.address?.society,
                      value:
                        memberDetails?.society ||
                        memberDetails?.address?.society,
                    },
                  ]
                : []
            }
            options={societyList}
            onChange={(e) => {
              setMemberDetails("society", e?.value);
            }}
          />
        </div>
        <div className="">
          <CreatableMultiselectMain
            className="placeholder:gray100"
            label="Area/Street"
            id="08"
            isMulti={false}
            placeholder="Select Area/Street"
            value={
              memberDetails?.streetName || memberDetails?.address?.streetName
                ? [
                    {
                      label:
                        memberDetails?.streetName ||
                        memberDetails?.address?.streetName,
                      value:
                        memberDetails?.streetName ||
                        memberDetails?.address?.streetName,
                    },
                  ]
                : []
            }
            options={streetNameList}
            onChange={(e) => {
              setMemberDetails("streetName", e?.value);
            }}
          />
        </div>
        <div className="">
          <CreatableMultiselectMain
            className="placeholder:gray100"
            label="City"
            id="09"
            isMulti={false}
            placeholder="Select City"
            value={
              memberDetails?.city || memberDetails?.address?.city
                ? [
                    {
                      label:
                        memberDetails?.city || memberDetails?.address?.city,
                      value:
                        memberDetails?.city || memberDetails?.address?.city,
                    },
                  ]
                : defaultCity
            }
            options={cityList}
            onChange={(e) => {
              setMemberDetails("city", e?.value);
            }}
          />
        </div>
        <div className="">
          <CreatableMultiselectMain
            className="placeholder:gray100"
            label="State"
            id="10"
            isMulti={false}
            placeholder="Select State"
            value={
              memberDetails?.state || memberDetails?.address?.state
                ? [
                    {
                      label:
                        memberDetails?.state || memberDetails?.address?.state,
                      value:
                        memberDetails?.state || memberDetails?.address?.state,
                    },
                  ]
                : defaultState
            }
            options={stateList}
            onChange={(e) => {
              setMemberDetails("state", e?.value);
            }}
          />
        </div>

        <div className="">
          <TextField
            className="placeholder:gray100"
            label="Pincode"
            id="11"
            placeholder="Enter Pincode"
            value={
              memberDetails.postalCode || memberDetails?.address?.postalCode
            }
            onChange={(e) => setMemberDetails("postalCode", e?.target?.value)}
          />
        </div>

        <div className="">
          <CreatableMultiselectMain
            className="placeholder:gray100"
            label="Country"
            id="12"
            isMulti={false}
            placeholder="Select Country"
            value={
              memberDetails?.country || memberDetails?.address?.country
                ? [
                    {
                      label:
                        memberDetails?.country ||
                        memberDetails?.address?.country,
                      value:
                        memberDetails?.country ||
                        memberDetails?.address?.country,
                    },
                  ]
                : defaultCountry
            }
            options={countryList}
            onChange={(e) => {
              setMemberDetails("country", e?.value);
            }}
          />
        </div>
        <div className="">
          <CreatableMultiselectMain
            className="placeholder:gray100"
            label="Select gender"
            isMulti={false}
            placeholder="Select gender"
            value={
              memberDetails?.gender
                ? [
                    {
                      label: memberDetails?.gender,
                      value: memberDetails?.gender,
                    },
                  ]
                : [
                    {
                      label: "male",
                      value: "male",
                    },
                  ]
            }
            options={genderType}
            onChange={(e) => {
              setMemberDetails("gender", e?.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
