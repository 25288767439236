import { api } from "../../services/api.service";

export const getUnindexedSocieties = async ({ currentPage = 1, pageSize = 10, queryParams }) => {
  const queryString = new URLSearchParams(queryParams).toString();

  const response = await api.get(`/address/unindexed-society?page=${currentPage}&pageSize=${pageSize}&${queryString}`);
  return response;
}

export const getIndexedSocieties = async (params) => {
  const queryString = new URLSearchParams(params).toString();
  const response = await api.get(`/address/society-index?${queryString}`);
  return response;
};

export const updateSocietyIndex = async (society) => {
  const response = await api.post("/address/society-index", society);
  return response;
};

export const downloadSocietyPDF = async (queryParams) => {
  const response = await api.post("/address/society-list", queryParams);
  return response;
};

export const downloadSocietyIndexPDF = async (queryParams) => {
  const response = await api.post("/address/society-report", queryParams);
  return response;
};

export const downloadSocietyMatchPDF = async (queryParams) => {
  const response = await api.post("/address/matching-society", queryParams);
  return response;
};

export const deleteSocietyIndex = async (id) => {
  const response = await api.delete(`/address/society-index/${id}`);
  return response;
};

export const updateSocietyLocation = async (data) => {
  const response = await api.post(`/address/society-index/location`, data);
  return response;
};
