import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { useReactNavigator } from "../../utils/reactNavigator";
import { getSevaDetail } from "./actionCreator";
import SevakTable from "./sevak";
import DeptTable from "./department";
import AddSevakModel from "./addSevakModel";
import { useSelector } from "react-redux";
import AddDepartmentModel from "./addDepartmentModel";
import { SevakStatus } from "../../constants/common.constants";

const SevaListIndex = () => {
  const { goBack } = useReactNavigator();
  const location = useLocation();
  const { UsersData } = useSelector(
    (state) => state.UserReducer
  );
  const sevaDetail = location.state?.sevaDetail;
  const parentDetail = location.state?.parentDetail ?? location.state?.sevaDetail;
  const [departmentData, setDepartmentData] = useState([])
  const [sevakData, setSevakData] = useState([])
  const [currentSevak, setCurrentSevak] = useState("");
  const [currentDepartment, setCurrentDepartment] = useState("")
  const [openSevakForm, setOpenSevakForm] = useState(false);
  const [openDepartmentForm, setOpenDepartmentForm] = useState(false)
  const [sevaksStatus, setSevaksStatus] = useState({})

  const fetchSevaDetail = async (parentDeptId, sevaId) => {
    let response = await getSevaDetail({ parentDeptId, sevaId });
    setDepartmentData(response.data.dept);
    setSevakData(response.data.sevak)
    console.log("first")
    if (response.data?.sevakStatusCounts) {
      setSevaksStatus(response.data?.sevakStatusCounts)
    }
    else {
      setSevaksStatus({})
    }

  };

  const openSevakFormLink = (id) => {
    if (id) {
      const currentIndex = sevakData.find((item) => item._id + "" === id + "");
      setCurrentSevak(currentIndex);
    }
    setOpenSevakForm(!openSevakForm);
  };

  const openDeptFormLink = (id) => {
    if (id) {
      const currentIndex = departmentData.find((item) => item._id + "" === id + "");
      setCurrentDepartment(currentIndex);
    }
    setOpenDepartmentForm(!openDepartmentForm);
  };

  useEffect(() => {
    fetchSevaDetail(parentDetail._id, sevaDetail._id);
  }, [parentDetail]);

  const handlePreviousPage = () => {
    goBack()
  }

  return (
    <>
      <div className="h-[calc(100vh_-_55px)] p-5 pb-5 bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="flex items-center">
            <Icon
              icon="icon-park-solid:left-c"
              className="text-3xl mr-3 cursor-pointer"
              onClick={handlePreviousPage}
            />
            <div className="text-[25px] text-[#212121]">{parentDetail?.title || parentDetail?.deptName}</div>
          </div>
        </div>
        <div className="bg-white w-full px-6 py-5 overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)] mb-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              {
                Object.keys(sevaksStatus).length > 0 &&
                SevakStatus.map((status) => (
                  <div key={status.value} className="flex justify-center items-center flex-col mx-2">
                    <div className="font-semibold text-lg">{status.label}</div>
                    <div className="text-xl">{sevaksStatus[status.value] || 0}</div>
                  </div>
                ))
              }
            </div>
            <div className="flex items-center">
              <Button
                color="blue"
                className="flex items-center min-w-[150px] mr-3"
                onClick={openSevakFormLink}
              >
                <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" />
                Add Sevak
              </Button>
              <Button
                color="blue"
                className="flex items-center min-w-[150px]"
                onClick={openDeptFormLink}
              >
                <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" />
                Add Department
              </Button>

            </div>
          </div>
        </div>
        <div className="bg-white w-full px-6 py-[30px] overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          {sevakData.length > 0 &&
            <div className="mb-10">
              <SevakTable
                data={sevakData}
                setData={setSevakData}
                sevaDetail={sevaDetail}
                openSevakFormLink={openSevakFormLink}
                fetchSevaDetail={fetchSevaDetail}
                sevaId={sevaDetail?._id}
                parentId={parentDetail._id}
              />
            </div>}
          {
            departmentData.length > 0 &&
            <div>
              <DeptTable
                data={departmentData}
                setData={setDepartmentData}
                sevaDetail={sevaDetail}
                openDeptFormLink={openDeptFormLink}
                fetchSevaDetail={fetchSevaDetail}
                sevaId={sevaDetail?._id}
                parentId={parentDetail._id}
              />
            </div>
          }
        </div>
      </div>
      <AddSevakModel
        open={openSevakForm}
        setOpen={setOpenSevakForm}
        sevakDetail={currentSevak}
        allUsers={UsersData}
        allSevak={sevakData}
        sevaId={sevaDetail?._id}
        parentId={parentDetail._id}
        fetchSevaDetail={fetchSevaDetail}
      />
      <AddDepartmentModel
        open={openDepartmentForm}
        setOpen={setOpenDepartmentForm}
        departmentDetail={currentDepartment}
        allDepartments={departmentData}
        sevaId={sevaDetail?._id}
        parentId={parentDetail._id}
        fetchSevaDetail={fetchSevaDetail}
      />
    </>
  );
};

export default SevaListIndex;
