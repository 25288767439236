import React from "react";
import { Typography } from "@material-tailwind/react";

import DepartmentList from "./DepartmentList";

const DepartmentHome = () => {
  return (
    <>
      <div className="h-[calc(100vh_-_81px)] 2xl:p-[20px] bg-[#F8F8F8] overflow-auto">
        <div className="mx-auto pb-10">
          <div className="w-max text-center m-2">
            <div className="text-[25px] text-[#212121]">Add Department</div>
          </div>
          <div className="flex w-full h-[calc(100vh_-135px)]">
            <div className="flex-grow overflow-auto ">
              <div className="bg-white w-full h-full px-2 py-4 rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
                <DepartmentList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentHome;
