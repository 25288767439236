import { api } from "../../services/api.service";

export const getAllSeva = async () => {
    const response = await api.get("seva/all");
    return response;
};

export const createSeva = async (formdata) => {
    try {
        const response = await api.post("seva/create", formdata, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return response;
    } catch (error) {
        console.error("Error uploading files:", error);
    }
};

export const updateSeva = async (formdata) => {
    try {
        const response = await api.post("seva/update", formdata);
        return response;
    } catch (error) {
        console.error("Error on updating seva", error);

    }
}

export const changeSevaStatus = async (sevaId, status) => {
    const response = await api.post("seva/changeSevaStatus", { _id: sevaId, enabled: status });
    return response;
}

export const reorderSevas = async (data) => {
    const response = await api.post("seva/reorder", data);
    return response;
}

export const deleteSeva = async (sevaId) => {
    const response = await api.post("seva/delete", { _id: sevaId });
    return response;
}

export const getSevaDetail = async (data) => {

    let url = `sevak/getSevak`;
    const response = await api.post(url, data);
    return response;
}

export const getAllUsers = async (gender) => {
    const response = await api.post("sevak/getAllUser", gender);
    return response;
}

export const createSevak = async (data) => {
    const response = await api.post("sevak/createSevak", data);
    return response;
}

export const updateSevak = async (data) => {
    const response = await api.post("sevak/updateSevak", data);
    return response;
}

export const deleteSevak = async (data) => {
    const response = await api.post("sevak/deleteSevak", data);
    return response;
}

export const changeSevakDuty = async (data) => {
    const response = await api.post("sevak/updateSevakDuty", data);
    return response;
}

export const createDepartment = async (data) => {
    const response = await api.post("sevaDept/createDept", data);
    return response;
}

export const updateDept = async (data) => {
    const response = await api.post("sevaDept/updateDept", data);
    return response;
}

export const deleteDepartment = async (data) => {
    const response = await api.post("sevaDept/deleteDept", data);
    return response;
}

