import React, { useState, useEffect } from "react";
import axios from "axios";
import Icon from "@mdi/react";
import { mdiTrashCan, mdiDownload, mdiEye } from "@mdi/js";
import { getSixDhamDarshan } from "./actionCreator";
import LoaderBar from "../Common/LoaderBar";
import ImgPreviewModal from "../Common/ImgPreviewModal/ImgPreviewModal";
import { s3Url } from "../../services/api.service";

const imglist = [
  {
    id: 1,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 2,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 3,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 4,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 5,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 6,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 7,
    path: "/assets/image/raksha.jpg",
  },
  {
    id: 8,
    path: "/assets/image/raksha.jpg",
  },
];

const SixDham = () => {
  const [darshanData, setDarshanData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [activeTab, setActiveTab] = useState(0);
  const [openImgPreviewModal, setOpenImgPreviewModal] = useState(false);
  const [previewImg, setPreviewImg] = useState("");

  const loadDailyDarshan = async () => {
    try {
      const response = await getSixDhamDarshan();
      if (response.data) {
        setDarshanData(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error Fetching Darshan:", error);
      setLoading(false);
    }
  };

  const handleMandirWiseData = (index) => {
    setActiveTab(index);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    loadDailyDarshan();
  }, []);

  const handleOpenImgPreview = (eachUrl) => {
    setPreviewImg(
      eachUrl.includes("https://") ? eachUrl : `${s3Url}${eachUrl}`
    );
    setOpenImgPreviewModal(!openImgPreviewModal);
  };

  const handleClosePreview = () => {
    setOpenImgPreviewModal(false);
  };

  return (
    <>
      {loading && <LoaderBar />}
      <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b">
          <div className="text-[25px] text-[#212121]">6 Dham Darshan</div>
        </div>
        <div className="bg-white w-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="flex gap-2">
            {darshanData?.map((data, index) => {
              return (
                <>
                  {/* <div>{data?.ShortName}</div> */}
                  <button
                    key={data._id}
                    style={{
                      padding: "10px 20px",
                      margin: "0 5px",
                      border: "1px solid #ccc",
                      backgroundColor: activeTab === index ? "#007bff" : "#fff",
                      color: activeTab === index ? "#fff" : "#000",
                      cursor: "pointer",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      handleMandirWiseData(index);
                      setLoading(true);
                    }}
                  >
                    {data.ShortName}
                  </button>
                </>
              );
            })}
          </div>

          <div className="mt-4">
            <h4>{darshanData[activeTab]?.mandir}</h4>
            <p className="mt-4">{darshanData[activeTab]?.fdate}</p>
            <div className="flex flex-wrap gap-10 mt-4">
              {darshanData[activeTab]?.images?.map((image, idx) => (
                <div className="rounded-[10px] overflow-hidden h-full relative">
                  <img
                    key={idx}
                    src={image}
                    alt={`Temple ${idx + 1}`}
                    style={{
                      width: "200px",
                      height: "150px",
                      objectFit: "cover",
                      borderRadius: "5px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      objectPosition: "center",
                    }}
                  />
                  <div className="absolute flex items-center justify-center gap-x-3 bg-[rgba(0,0,0,0.60)] opacity-0 inset-0 hover:opacity-100 transition-all duration-300 ease-[ease]">
                    <button
                      className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]"
                      onClick={() => handleOpenImgPreview(image)}
                    >
                      <Icon path={mdiEye} size={1} />
                    </button>

                    <button className="w-10 h-10 bg-white flex items-center justify-center p-1.5 rounded-[50%] transition-all duration-300 ease-[ease] hover:scale-[1.06]">
                      <Icon path={mdiDownload} size={1} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ImgPreviewModal
        previewImg={previewImg}
        closeModal={handleClosePreview}
        show={openImgPreviewModal}
      />
    </>
  );
};

export default SixDham;
