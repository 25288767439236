import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Button } from "@material-tailwind/react";
import { Switch } from "@headlessui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { toastr } from "../../services/common.service";
import { s3Url } from "../../services/api.service";
// import {
//   changeSevaStatus,
//   deleteSeva,
//   getAllSevas,
//   reorderSevas,
// } from "../photo-banner/actionCreator";
import { useReactNavigator } from "../../utils/reactNavigator";
import { SevaListData } from "../Data/FackData";
import { changeSevaStatus, deleteSeva, getAllSeva, getAllUsers, reorderSevas } from "./actionCreator";
import moment from "moment";
import clsx from "clsx";
import AlertModal from "../Common/CustomConfirmationModal/CustomConfirmationModal";
import AddSevaModel from "./AddSevaModel";
import { useDispatch } from "react-redux";
import { ACTION_TYPES } from "../../redux/constants";

const columnHelper = createColumnHelper();

const DraggableRow = ({ row, reorderSeva, onClick }) => {
  const [, dropRef] = useDrop({
    accept: "row",
    drop: (draggedRow) => reorderSeva(draggedRow.index, row.index),
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: "row",
  });

  const MotionTr = forwardRef((props, ref) => (
    <motion.tr ref={ref} {...props} className="text-lg border-b border-gray-200 cursor-pointer" />
  ));

  return (
    <MotionTr
      ref={previewRef}
      initial={{ opacity: 1 }}
      animate={{ opacity: isDragging ? 0.5 : 1 }}
      exit={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      onClick={onClick}
    >
      <td ref={dropRef} className="text-start w-[50px]">
        <button ref={dragRef} className="cursor-grab">
          <Icon icon="grommet-icons:drag" className="text-xl" />
        </button>
      </td>
      {row.getVisibleCells().map((cell) => (
        <td key={cell.id} className="p-2">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </MotionTr>
  );
};

const SevaList = () => {
  const [data, setData] = useState([]);
  const { navigate } = useReactNavigator();
  const [open, setOpen] = useState(false);
  const [currentSevaDetail, setcurrentSevaDetail] = useState({});
  const [showAlertModel, setShowAlertModel] = useState(false);
  const [currentSevaId, setCurrentSevaId] = useState(null)
  const dispatch = useDispatch()

  const openCreateLinkModal = (_id) => {
    setOpen(!open);
    const currentData = data.find((item) => item._id + "" === _id + "");
    setcurrentSevaDetail(currentData);
  };

  const fetchAllUsers = async (gender) => {
    const result = await getAllUsers({ gender })
    dispatch({ type: ACTION_TYPES.SET_USER, payload: result.data })

  }

  useEffect(() => {

    fetchAllUsers("male")
  }, [])


  const handleOpenDeleteModal = (_id) => {
    setShowAlertModel(true);
    setCurrentSevaId(_id);
  };

  const getAllSevaDetail = async () => {
    try {
      let sevaList = await getAllSeva()
      setData(sevaList.data);
    } catch (err) {
      console.error("Error getting album detail:", err);
    }
  };

  useEffect(() => {
    getAllSevaDetail();
  }, []);

  const reorderSeva = (draggedRowIndex, targetRowIndex) => {
    data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
    setData([...data]);
    const sevaIds = data.map((d) => d._id);
    reorderSevas({ sevaIds })
      .then(() => {
        getAllSevaDetail();
      })
      .catch((err) => {
        toastr.show("Something went wrong", "error");
      });
  };

  const changeVisibility = async (sevaId, status) => {
    try {
      const response = await changeSevaStatus(sevaId, status);
      if (response) {
        toastr.show("Seva status changed successfully!", "success");
        getAllSevaDetail();
      }
    } catch (err) {
      console.error("Error changing seva status:", err);
    }
  };

  const handleNavigate = (row) => {
    navigate(`${row.original?._id}`, {
      sevaDetail: row.original,
    });
  }

  const columns = [
    // columnHelper.accessor("imageURL", {
    //   cell: (info) => (
    //     <img
    //       src={
    //         info.getValue()
    //           ? `${s3Url}${info.getValue()}`
    //           : "/assets/image/no-thumbnail.png"
    //       }
    //       className="h-[50px] rounded-lg"
    //     />
    //   ),
    //   header: () => <span>Thumbnail</span>,
    // }),
    columnHelper.accessor("title", {
      cell: (info) => info.getValue(),
      header: () => <span>Seva</span>,
    }),
    columnHelper.accessor("sevakCount", {
      cell: (info) => info.getValue(),
      header: () => <span>Total Sevak</span>,
    }),
    columnHelper.accessor("fromDate", {
      cell: (info) => {
        let date = info.getValue()
        return date ? moment(date).format("DD-MM-YYYY") : "-"
      },
      header: () => <span>Start Date</span>,
    }),
    columnHelper.accessor("toDate", {
      cell: (info) => {
        let date = info.getValue()
        return date ? moment(date).format("DD-MM-YYYY") : "-"
      },
      header: () => <span>To Date</span>,
    }),
    columnHelper.accessor("enabled", {
      cell: (info) => (
        <Switch
          // checked={info.getValue()}
          className={`${info.getValue() ? "bg-blue-600" : "bg-gray-200"
            } relative inline-flex h-6 w-11 items-center rounded-full`}
          onClick={(e) => {
            e.stopPropagation();
            changeVisibility(info.row.original._id, !info.getValue())
          }
          }
        >
          <span
            className={`${info.getValue() ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch >
      ),
      header: () => <span>Visibility</span>,
    }),
    columnHelper.display({
      header: () => <span className="text-center">Actions</span>,
      id: "actions",
      cell: (props) => {
        return (
          <span className="flex items-center justify-center">
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
              <button onClick={(e) => { e.stopPropagation(); openCreateLinkModal(props.row.original?._id) }}>
                <Icon
                  icon="ic:baseline-edit"
                  className="text-2xl transition-all duration-150 text-black900"
                />
              </button>
            </span>
            <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
              <button onClick={(e) => { e.stopPropagation(); handleOpenDeleteModal(props.row.original?._id) }}>
                <Icon
                  icon="material-symbols:delete"
                  className="transition-all duration-150 text-2xl text-black900"
                />
              </button>
            </span>
          </span>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
  });

  const deleteSevaConfirmation = async (status) => {
    if (status) {
      try {
        if (currentSevaId) {
          await deleteSeva({ _id: currentSevaId });
          getAllSevaDetail();
          toastr.show("Delete book!", "success");
        }
      } catch (err) {
        toastr.show("Something went wrong", "error");
      }
    }
    setShowAlertModel(false);
  };

  return (
    <>
      <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Seva List</div>
          <Button
            color="blue"
            className="flex items-center min-w-[150px]"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" /> Add Seva
          </Button>
        </div>

        <div className="bg-white w-full px-6 py-[30px] overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="overflow-auto">
            <DndProvider backend={HTML5Backend}>
              <table className="w-full">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                        <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              colSpan={header.colSpan}
                              className="text-left font-semibold p-2"
                            >
                              {header.isPlaceholder ? null : (
                                <div
                                  {...{
                                    className: clsx(
                                      header.column.getCanSort()
                                        ? "cursor-pointer select-none"
                                        : "",
                                      "last:text-center text-lg"
                                    )
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </div>
                              )}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody className="last:!border-b-0">
                  {table.getRowModel().rows.map((row) => (
                    <DraggableRow
                      key={row.id}
                      row={row}
                      reorderSeva={reorderSeva}
                      onClick={() => handleNavigate(row)}

                    />
                  ))}
                </tbody>
              </table>
            </DndProvider>
          </div>
        </div>
      </div>
      <AlertModal
        open={showAlertModel}
        handleCloseModal={deleteSevaConfirmation}
        messageText="Are you sure you want to delete this book?"
        submitBtnText="Confirm"
        buttonType="primary"
        icon="icon-park:go-end"
      />
      <AddSevaModel open={open} setOpen={setOpen} currentSevaDetail={currentSevaDetail} getAllSevaDetail={getAllSevaDetail} />
    </>
  );
};

export default SevaList;
