import React from "react";
import { useRoutes } from "react-router-dom";
import Katha from "../../../Components/katha-pdf/katha";

export default function KathaRouter() {
  const KathaRoute = [
    {
      path: "katha",
      element: (
        <>
          <Katha />
        </>
      ),
    },
  ];
  const KathaRouter = useRoutes(KathaRoute);
  return KathaRouter;
}
