import { Button, Input } from "@material-tailwind/react";
import Icon from "@mdi/react";
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, DialogPanel, Switch, Transition, TransitionChild } from "@headlessui/react";
import { mdiClose } from "@mdi/js";
import { toastr } from "../../services/common.service";
import { createDepartment, createSevak, updateDept, updateSevak } from "./actionCreator";
import { useSelector } from "react-redux";
import SelectField from "../Common/Input/SelectField";
import { SevakStatus } from "../../constants/common.constants";

const AddDepartmentModel = ({
    open,
    setOpen,
    departmentDetail,
    allDepartments,
    sevaId,
    parentId,
    fetchSevaDetail
}) => {
    const [loggedInUser, setLoggedInUser] = useState({})
    const [departmentInfo, setDepartmentInfo] = useState({})
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        let fetchLoggedInUser = localStorage.getItem("loggedInUser");
        setLoggedInUser(JSON.parse(fetchLoggedInUser));
    }, [])


    useEffect(() => {
        // if (sevakDetail && sevakDetail?._id) {
        //     setEnabled(sevakDetail?.isHead);
        //     setOnDuty(sevakDetail?.onDuty);
        //     let name = `${sevakDetail?.userId.firstName} ${sevakDetail?.userId?.middleName} ${sevakDetail?.userId?.lastName} | ${sevakDetail?.userId?.mobile}`;
        //     let fieldName = {
        //         label: name,
        //         value: name
        //     };
        //     setUserInfo(
        //         {
        //             ...userInfo,
        //             SMVNo: sevakDetail?.userId?.SMVNo.toString(),
        //             fullName: fieldName,
        //             sevakStatus: SevakStatus[sevakDetail?.sevakStatus]
        //         }
        //     );

        // }
        if (departmentDetail && departmentDetail?._id) {
            setEnabled(departmentDetail?.isCommon);
            setDepartmentInfo({
                ...departmentInfo,
                deptName: departmentDetail?.deptName
            });

        }
    }, [departmentDetail])

    const resetForm = () => {
        setDepartmentInfo({});
        setOpen(false);
    };




    const addNewDepartment = async () => {
        try {
            if (departmentInfo.deptName === null || departmentInfo.deptName === undefined || departmentInfo.deptName === "") {
                return toastr.show("Please Enter Department name", "error");
            }

            let formData = {
                deptName: departmentInfo.deptName,
                sevaId,
                parentId,
                isCommon: enabled,
            }
            await createDepartment(formData);
            await fetchSevaDetail(parentId, sevaId);
            resetForm()

        } catch (err) {
            console.error("Error uploading files:", err);
        }
    };

    const updateDepartment = async () => {
        try {
            if (departmentInfo.deptName === "" || departmentInfo.deptName === null || departmentInfo.deptName === undefined) {
                return toastr.show("Please Enter Department name", "error");
            }


            const formData = {
                _id: departmentDetail?._id,
                deptName: departmentInfo.deptName,
                isCommon: enabled,
            }

            await updateDept(formData);
            await fetchSevaDetail(parentId, sevaId);

            resetForm()
        } catch (err) {
            console.error("Error uploading files:", err);
        }

    };





    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={() => { }}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </TransitionChild>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <DialogPanel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="flex justify-between pb-4 border-b border-gray400">
                                        <div className="text-lg font-semibold text-orange-600">
                                            Add Seva Department
                                        </div>
                                        <Icon
                                            path={mdiClose}
                                            size={1}
                                            className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                                            onClick={resetForm}
                                        />
                                    </div>
                                    <div className="p-6">
                                        <div className="flex flex-col">
                                            <div className="flex flex-col">
                                                <div className="flex-grow flex justify-center">
                                                    <div className="max-w-lg w-full">
                                                        <div className="bg-white w-full px-10 py-[30px] rounded-[10px]">
                                                            <div className=" mb-5">
                                                                <Input label="Department Name" value={departmentInfo?.deptName} onChange={(e) => setDepartmentInfo({ ...departmentInfo, deptName: e.target.value })} />
                                                            </div>

                                                            <div class="flex item-center mb-5">
                                                                <div className="flex items-center mr-5">
                                                                    <div className="mr-3  text-lg">
                                                                        Is Common?
                                                                    </div>
                                                                    <Switch
                                                                        checked={enabled}
                                                                        onChange={setEnabled}
                                                                        className={`${enabled ? "bg-blue-600" : "bg-gray-200"
                                                                            } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                    >
                                                                        <span
                                                                            className={`${enabled
                                                                                ? "translate-x-6"
                                                                                : "translate-x-1"
                                                                                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                        />
                                                                    </Switch>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-right flex justify-end mt-6">
                                            <div className="mr-5">
                                                <Button
                                                    color="red"
                                                    variant="outlined"
                                                    className="capitalize text-base"
                                                    onClick={resetForm}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div>
                                                <Button
                                                    className="capitalize text-base bg-orange-600"
                                                    onClick={() =>
                                                        departmentDetail?._id
                                                            ? updateDepartment()
                                                            : addNewDepartment()
                                                    }
                                                >
                                                    {departmentDetail?._id ? "Update" : "Create"}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default AddDepartmentModel;
